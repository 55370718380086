<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <section>
                    <div>
                        <h1>
                            <i>Yikes! We're not quite sure what you're looking for...</i>
                        </h1>
                        <div class="content-block">
                            <p>
                                Either this page doesn't exist or you took a wrong turn somewhere.
                                Come back in the front door to My Emporium and try again!
                            </p>
                            <tie-Button class="button" @click="errorRedirect()">
                                Return To My Emporium
                            </tie-button>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>

<script>
import TieButton from '../../components/tie-button/TieButton';

export default {
    name: 'ErrorPage',

    components: {
        TieButton
    },

    created(){
        setTimeout( () => this.$router.push({ path: '/' }
        ), 10000);
    },

    methods: {
        errorRedirect() {
            this.$router.push({
                path: '/'
            });
        }
    }
};

</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";
@import "../../assets/styles/container";

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

a {
    line-height: inherit;
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: $primary-color-link-hover-dark;
    }
}

@media (max-width: 1200px) {
    .container-wrapper {
        font-size: 80%;
    }
}
</style>