<template>
    <label v-if="label.length > 0" :for="identifier">{{ label }}</label>
    <textarea
        :id="identifier"
        :value="modelValue"
        name="text-input"
        autocomplete="off"
        :placeholder="placeholder"
        class="text-input"
        :class="{ error: error && error.length > 0 }"
        type="text"
        cols="30"
        rows="10"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';

export default {
    name: 'TextArea',

    components: {
        TieAlert
    },

    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        identifier: {
            type: String,
            required: false,
            default: 'text-input',
        },

        placeholder: {
            type: String,
            required: false,
            default: '',
        },

        'modelValue': {
            type: String,
            required: false,
            default: '',
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            error: '',
        };
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/forms";

textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 1rem;
    padding: 0.9375rem;
    border: 1px solid $form-border-color;
    border-bottom-color: $form-border-bottom-color;
    border-radius: 3px;
    background-color: $background-color;
    box-shadow: none;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $form-text-color;
    transition: border-color 0.25s ease-in-out;
    appearance: none;

    &.error {
        border-color: $danger;
    }

    &:focus,
    &:focus-visible,
    &:active {
        border-color: $primary;
    }

    &:focus-visible {
        outline-color: $primary;
    }

    &[row] {
        height: auto;
    }
}

label {
    font-weight: bold;
    margin: 14px 0 3px;
    position: relative;
}

</style>
