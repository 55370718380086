<template>
    <div class="input-container">
        <input
            :id="identifier"
            type="checkbox"
            :value="modelValue"
            @change="onChange"
        >
        <label :for="identifier" class="is-checkbox"><slot /></label>
    </div>
</template>

<script>
export default {
    name: 'CheckBox',

    props: {
        identifier: {
            type: String,
            required: false,
            default: 'checkbox',
        },
        'modelValue': {
            type: Boolean,
            required: true,
            default: false,
        }
    },

    emits: ['update:modelValue'],

    methods: {
        onChange() {
            let inputValue = document.getElementById(this.identifier).checked;
            this.$emit('update:modelValue', inputValue);
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.input-container {
    margin-bottom: 10px;
    user-select: none;

    label {
        position: relative;
        margin-left: 20px;
        padding-left: 10px;
        width: calc(100% - 20px);
        display: flex;

        &::after {
            content: "";
            position: absolute;
            top: -5px;
            left: -20px;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            background-color: white;
            border: 1px solid $primary;
            transform: rotate(0);
            pointer-events: none;
            text-align: center;
            color: $primary;
            align-self: flex-end;
        }
    }

    input[type=checkbox] {
        opacity: 0;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
        position: absolute;

        &:checked + label::after {
            content: "\2713";
            color: $primary;
        }
    }
}
</style>