<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask " :class="center ? 'center' : ''">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <h3>
                            <slot name="header" />
                        </h3>
                        <span class="close" @click="$emit('close')" />
                    </div>

                    <div class="modal-body content-block">
                        <slot name="body" />
                    </div>

                    <div class="modal-footer btn-container">
                        <slot name="footer">
                            <tie-button variation="btn-primary" @click="$emit('close')">
                                CLOSE
                            </tie-button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import TieButton from '../tie-button/TieButton';

export default {
    name: 'TieModal',

    components: {
        TieButton
    },

    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        center: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    emits: ['close'],

    watch: {
        show(value) {
            if (value) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
@import "src/assets/styles/container";

.close {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}

.close:hover {
    opacity: 1;
}

.close::before,
.close::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333333;
}

.close::before {
    transform: rotate(45deg);
}

.close::after {
    transform: rotate(-45deg);
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    font-family: $default-fonts;

    &.center {
        align-items: center;
    }
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    max-width: 800px;
    flex: 1;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #F8F8F8;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-header {
    position: relative;

    h3 {
        margin-top: 0;
        text-align: center;
        color: $primary;
        padding-right: 40px;
    }
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>