<template>
    <div class="home-container">
        <h1>Reset PASSWORD</h1>

        <p class="subtitle">
            Please enter your new password. It must contain at least two digits, one capital letter and be between 8 and
            30 characters in length.
        </p>

        <tie-alert v-if="error">
            {{ error }}
        </tie-alert>

        <div class="card access-form">
            <form @keyup.enter="submitForgotPasswordReset">
                <password-input
                    v-model="password"
                >
                    Password
                </password-input>
                <tie-alert v-if="password && !validPassword">
                    password is not valid
                </tie-alert>

                <password-input
                    v-model="passwordConfirmation"
                    identifier="passwordConfirmation"
                >
                    Confirm Password
                </password-input>
                <tie-alert v-if="passwordConfirmation && !validPasswordConfirmation">
                    passwords do not match
                </tie-alert>
            </form>
        </div>

        <div class="btn-container">
            <tie-button
                :is-disabled="!canReset"
                variation="btn-login"
                @click="submitForgotPasswordReset"
            >
                Reset
            </tie-button>
            <p>
                I know my password,
                <router-link to="/">
                    Back to Login
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import PasswordInput from '../../components/password-input/PasswordInput';
import TieButton from '../../components/tie-button/TieButton';
import TieAlert from '../../components/tie-alert/TieAlert';
import Axios from 'axios';

export default {
    name: 'ForgotPasswordReset',

    components: {
        TieAlert,
        PasswordInput,
        TieButton,
    },

    data() {
        return {
            password: '',
            validPassword: false,
            passwordConfirmation: '',
            validPasswordConfirmation: false,
            success: false,
            error: null,
        };
    },

    computed: {
        /**
         * Returns TRUE if both password inputs have been filled out and are valid and that they match.
         *
         * @returns {string|boolean}
         */
        canReset() {
            return this.password &&
                this.validPassword &&
                this.passwordConfirmation &&
                this.validPasswordConfirmation &&
                this.password === this.passwordConfirmation;
        },

        /**
         * Returns the ID from the URL
         *
         * @returns {string | RouteParamValue[]}
         */
        id() {
            return this.$route.params.id;
        },
    },

    watch: {
        /**
         * Watches the this.password and checks the validation on change
         *
         * @returns {boolean}
         */
        password() {
            this.validatePassword();
            if (this.passwordConfirmation) {
                return this.validatePasswordConfirmation();
            }

        },

        /**
         * Watches the this.password and checks the validation on change
         *
         * @returns {boolean}
         */
        passwordConfirmation() {
            return this.validatePasswordConfirmation();
        },
    },

    methods: {
        /**
         * Validates the password field
         *
         * @returns {boolean}
         */
        validatePassword() {
            if (/^(?=.*\d{2,})(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,30}$/.test(this.password)) {
                return this.validPassword = true;
            } else {
                return this.validPassword = false;
            }
        },

        /**
         * Validates the password confirmation field
         *
         * @returns {boolean}
         */
        validatePasswordConfirmation() {
            if (this.passwordConfirmation && this.password === this.passwordConfirmation) {
                return this.validPasswordConfirmation = true;
            } else {
                return this.validPasswordConfirmation = false;
            }
        },

        /**
         * Checks if the form can be submitted and posts it to the API
         *
         * @returns {Promise<NavigationFailure | void | undefined>}
         */
        submitForgotPasswordReset() {
            if (!this.canReset) {
                return;
            }
            let data = {
                attributes: {
                    dob: '0-0-0',
                    email: '',
                    password: this.password,
                    key: this.id,
                },
                id: this.id,
                type: 'forgot-password'
            };

            Axios.patch(`forgot-password/${ this.id }`, { data })
                .then(() => {
                    return this.$router.push({
                        path: '/',
                        query: {
                            message: 'forgot-password',
                        },
                    });
                })
                .catch(() => {
                    return this.error = 'Oops! Something seems to have gone wrong. Please try again or get in touch if this keeps happening.';
                });
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";
</style>