// src/utils/analytics.js
export const gtagReady = new Promise((resolve) => {
    if (window.gtag) {
        resolve();
    } else {
        window.addEventListener('gtagLoaded', () => {
            resolve();
        });
    }
});

export const trackEvent = ({ event, eventCategory, eventAction, eventLabel }) => {
    return new Promise((resolve, reject) => {
        if (window.gtag) {
            window.gtag('event', event, {
                event_category: eventCategory,
                event_action: eventAction,
                event_label: eventLabel,
                event_callback: () => {
                    resolve();
                }
            });
        } else {
            reject('gtag is not available');
        }
    });
};