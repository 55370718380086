<template>
    <label>Date of Birth</label>
    <div class="dob-container">
        <select
            v-model="day"
            name="day"
            :class="{ error: error && error.length > 0 }"
        >
            <option
                :value="null"
                disabled=""
                selected=""
            >
                DD
            </option>
            <option
                v-for="d in dayRange"
                :key="d"
                :value="d"
            >
                {{ d }}
            </option>
        </select>
        <select
            v-model="month"
            name="month"
            :class="{ error: error && error.length > 0 }"
        >
            <option
                :value="null"
                disabled=""
                selected=""
            >
                MM
            </option>
            <option
                v-for="m in monthRange"
                :key="m"
                :value="m"
            >
                {{ m }}
            </option>
        </select>
        <select
            v-model="year"
            name="year"
            :class="{ error: error && error.length > 0 }"
        >
            <option
                :value="null"
                disabled=""
                selected=""
            >
                YYYY
            </option>
            <option
                v-for="y in yearRange"
                :key="y"
                :value="y"
            >
                {{ y }}
            </option>
        </select>
    </div>
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';
import moment from 'moment';

export default {
    name: 'DobInput',

    components: {
        TieAlert
    },

    props: {
        'modelValue': {
            type: Object,
            required: false,
            default() {
                return {
                    day: null,
                    month: null,
                    year: null
                };
            },
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            day: null,
            month: null,
            year: null,
            error: '',
        };
    },

    computed: {
        yearRange() {
            let yearRange = [];
            const minYear = moment().subtract(110, 'years').year();
            const maxYear = moment().subtract(18, 'years').year();

            for (let i = maxYear; i > minYear; i--) {
                yearRange.push(i);
            }
            return yearRange;
        },

        monthRange() {
            let monthRange = [];
            for (let i = 1; i < 13; i++) {
                var month = i;
                if (month > 0 && month < 10) {
                    month = ('0' + month).slice(-2);
                }
                monthRange.push(month);
            }

            return monthRange;
        },

        dayRange() {
            let dayRange = [];
            for (let i = 1; i < 32; i++) {
                var day = i;
                if (day > 0 && day < 10) {
                    day = ('0' + day).slice(-2);
                }
                dayRange.push(day);
            }

            return dayRange;
        }
    },

    watch: {
        day() {
            this.updateDob();
        },

        month() {
            this.updateDob();
        },

        year() {
            this.updateDob();
        },
    },

    mounted() {
        if (this.modelValue) {
            this.year = this.modelValue['year'];
            this.month = this.modelValue['month'];
            this.day = this.modelValue['day'];
        }
    },

    methods: {
        updateDob() {
            this.$emit('update:modelValue', {
                day: this.day ? this.day.toString() : this.day,
                month: this.month ? this.month.toString() : this.month,
                year: this.year ? this.year.toString() : this.year
            });
        },

        validateDob() {
            if (this.day && this.month && this.year) {
                this.error = '';
                return true;
            } else {
                this.error = 'Please enter a valid date of birth';
                return false;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

label {
    font-weight: bold;
    margin: 14px 0 3px;
    position: relative;
}

.dob-container {
    margin: 0 0 1rem;

    select {
        width: 32%;
        height: 50px;
        border: 1px solid #F0F0F0;
        border-bottom-color: #DDDDDD;
        border-radius: 3px;
        background-color: #F8F8F8;
        padding: 5px;
        appearance: none;
        color: #0A0A0A;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%280, 0, 0%29"></polygon></svg>');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 9px 6px;

        &.error {
            border-color: #EC0044;
        }

        &:focus,
        &:focus-visible,
        &:active {
            border-color: $primary-color;
            outline-color: $primary;
        }

        &:not(:last-child) {
            margin-right: 2%;
        }
    }
}
</style>
