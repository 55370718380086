import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/registration/LoginPage';
import ForgotPassword from '../views/registration/ForgotPasswordForm';
import ForgotPasswordReset from '../views/registration/ForgotPasswordReset';
import Register from '../views/registration/Register';
import RegisterUser from '../views/registration/RegisterUser';
import RegisterComplete from '../views/registration/RegisterComplete';
import Dashboard from '../views/dashboard';
import MyPolicies from '../views/dashboard/MyPolicies';
import PolicyDetails from '../views/dashboard/MyPolicies/MyPolicyDetails';
import PolicyUpgrade from '../views/dashboard/MyPolicies/MyPolicyDetails/MyPolicyUpgrade';
import PolicyUpgradeSummary from '../views/dashboard/MyPolicies/MyPolicyDetails/MyPolicyUpgrade/MyPolicyUpgradeSummary';
import PolicyUpgradeConfirmation from '../views/dashboard/MyPolicies/MyPolicyDetails/MyPolicyUpgrade/MyPolicyUpgradeSummary/UpgradeConfirmation';
import CustomerDetails from '../views/dashboard/CustomerDetails/CustomerDetails';
import SecurityDetails from '../views/dashboard/SecurityDetails/SecurityDetails';
import ClaimCentre from '../views/dashboard/ClaimCentre/ClaimCentre';
import ContactUs from '../views/dashboard/ContactUs/ContactUs';
import ErrorPage from '../views/error/index';
import store from '../store';

import Axios from 'axios';

function guardMyRoute(to, from, next) {
    if (from) {
        store.dispatch('setPrevPage', from);
    }

    if (localStorage.getItem('authToken')) {
        // Call Refresh endpoint on navigation
        if (to.name !== 'Logout') {
            store.dispatch('refresh');
        }
        // Attempt to navigate to /dashboard -> send to my policies
        if (to.name == 'Dashboard') {
            next('/dashboard/my-policies');
            return;
        }
        next();
    } else {
        next('/');
    }
}

function isLoggedIn(to, from, next) {
    if (localStorage.getItem('authToken')) {
        next('/dashboard/my-policies');
    } else {
        next();
    }
}

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        beforeEnter: isLoggedIn
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            store.dispatch('logout').then(() => {
                return next({
                    name: 'Login',
                    query: {
                        message: 'logout',
                    },
                });
            });
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        beforeEnter: isLoggedIn
    },
    {
        path: '/forgot-password/:id',
        name: 'ForgotPassword-reset',
        component: ForgotPasswordReset,
        beforeEnter: (to, from, next) => {
            const id = to.params.id;
            Axios.get(`forgot-password/${ id }`)
                .then(() => {
                    return isLoggedIn(to, from, next);
                }).catch(() => {
                    return next(
                        {
                            path: 'forgot-password',
                            query: {
                                message: 'link-issue',
                            },
                        }
                    );
                });
        }
    },
    {
        path: '/registration/:id',
        name: 'RegisterUser',
        component: RegisterUser,
    },
    {
        path: '/confirm-email/:id',
        beforeEnter: (to, from, next) => {
            const id = to.params.id;
            Axios.delete(`confirm-email/${ id }`)
                .then(() => {
                    store.dispatch('logout').then(() => {
                        next('/?message=confirm-email');
                    });
                    return;
                })
                .catch(() => {
                    store.dispatch('logout').then(() => {
                        next('/?message=error-email');
                    });
                    return;
                });
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        beforeEnter: isLoggedIn
    },
    {
        path: '/register-complete',
        name: 'RegisterComplete',
        component: RegisterComplete,
        beforeEnter: isLoggedIn
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: guardMyRoute,
        children: [
            {
                path: 'my-policies',
                name: 'MyPolicies',
                component: MyPolicies
            },
            {
                path: '/dashboard/my-policies/:id',
                name: 'PolicyDetails',
                component: PolicyDetails
            },
            {
                path: '/dashboard/my-policies/:id/upgrade',
                name: 'PolicyUpgrade',
                component: PolicyUpgrade
            },
            {
                path: '/dashboard/my-policies/:id/upgrade/:upgradeid',
                name: 'PolicyUpgradeSummary',
                component: PolicyUpgradeSummary
            },
            {
                path: '/dashboard/my-policies/:id/upgrade/:upgradeid/confirmation',
                name: 'PolicyUpgradeConfirmation',
                component: PolicyUpgradeConfirmation
            },
            {
                path: 'customer-details',
                name: 'CustomerDetails',
                component: CustomerDetails
            },
            {
                path: 'security-details',
                name: 'SecurityDetails',
                component: SecurityDetails
            },
            {
                path: 'contact-us',
                name: 'ContactUs',
                component: ContactUs
            },
            {
                path: 'claim-centre',
                name: 'ClaimCentre',
                component: ClaimCentre,
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'ErrorPage',
                component: ErrorPage
            },
        ]
    },
    {
        path: '/tie',
        beforeEnter() {
            location.href = 'https://www.theinsuranceemporium.co.uk/';
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'ErrorPage',
        component: ErrorPage
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: 'smooth' };
        } else {
            window.scrollTo(0, 0);
        }
    }
});

export default router;
