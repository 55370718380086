<template>
    <div class="loading">
        <div class="retrieving">
            Loading Portal
        </div>
        <div
            id="global-loading"
            class="scheme__loader"
        />
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.loading {
    &::after {
        content: url("../../../src/assets/images/loading-logo.svg");
        position: fixed;
        z-index: 100000;
        left: 0;
        top: 50%;
        right: 0;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        transition: left 0.2s;
        transform: translateY(-50%);
    }

    &::before {
        content: '';
        position: fixed;
        z-index: 100000;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: left 0.3s;
        background: #00ABC7;
    }

    > .retrieving {
        display: block;
        z-index: 100000;
        position: fixed;
        font-family: $default-fonts;
        left: 0;
        right: 0;
        top: 60%;
        width: 100%;
        text-align: center;
        color: #FFFFFF;
        font-size: 20px;
        transition: left 0.3s;

        &::after {
            content: '';
            animation: LoadingDots 2s infinite;
            font-size: 50px;
            line-height: 10px;
            display: block;
        }
    }
}

@keyframes LoadingDots {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
    100% { content: '....'; }
}
</style>
