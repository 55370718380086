<template>
    <div class="home-container">
        <h1>FORGOTTEN PASSWORD</h1>
        <p class="subtitle">
            Please enter your email address and date of birth below. You will then receive an email link to create a new password.
        </p>

        <tie-alert v-if="success" variant="success">
            If the email address is associated with an Emporium account, an email will be sent containing further instructions.
        </tie-alert>

        <tie-alert v-if="error">
            Something went wrong, please call us on <a href="tel:03300244005">03300 244 005</a>.
        </tie-alert>

        <tie-alert v-if="linkIssue">
            We are sorry but there was an issue with this link. Please enter your email and date of birth below and we will send you a new link.
        </tie-alert>

        <div class="card access-form">
            <form @keyup.enter="submitForgotPassword">
                <email-input
                    v-model="email"
                >
                    Email
                </email-input>

                <dob-input
                    v-model="dob"
                />
            </form>
        </div>

        <div class="btn-container">
            <tie-button
                :is-disabled="!canForgot"
                variation="btn-login"
                @click="submitForgotPassword"
            >
                Reset
            </tie-button>
            <p>
                I know my password,
                <router-link to="/">
                    Back to Login
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import TieButton from '../../components/tie-button/TieButton';
import TieAlert from '../../components/tie-alert/TieAlert';
import EmailInput from '../../components/email-input/EmailInput';
import DobInput from '../../components/dob-input/DobInput';

export default {
    name: 'ForgotPasswordForm',

    components: {
        TieButton,
        TieAlert,
        EmailInput,
        DobInput
    },

    data() {
        return {
            email: '',
            validEmail: false,
            validDob : false,
            dob: {
                day: null,
                month: null,
                year: null
            },
            success: false,
            requestActive: false,
            error: false
        };
    },

    computed: {
        linkIssue() {
            return this.$route.query.message === 'link-issue';
        },

        canForgot() {
            return (this.validEmail && this.validDob);
        }
    },

    watch: {
        email() {
            this.validateEmail();
        },
        dob() {
            this.validateDob();
        }
    },

    methods: {
        validateDob() {
            if (this.dob.day && this.dob.month && this.dob.year) {
                this.validDob = true;
            } else {
                this.validDob = false;
            }
        },

        validateEmail() {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.validEmail = true;
            } else {
                this.validEmail = false;
            }
        },

        submitForgotPassword() {
            if (this.requestActive || !this.canForgot) {
                return;
            }
            this.error = false;
            this.requestActive = true;

            let data = {
                attributes: {
                    email: this.email,
                    dob: `${this.dob.year}-${this.dob.month}-${this.dob.day}`,
                },
                id: '1',
                type: 'forgot-password'
            };
            this.$store.dispatch('forgotPassword', data).then(() => {
                this.success = true;
                this.requestActive = false;
            }).catch(() => {
                // error message if this fails
                this.error = true;
                this.requestActive = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";

</style>