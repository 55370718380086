<template>
    <div class="content-block content-block-padded">
        <template v-for="item in dataToDisplay">
            <dl v-if="item.isDisplayed !== false" :key="item.title">
                <dt>{{ item.title }}</dt>
                <dd v-html="item.value" />
            </dl>
        </template>
    </div>
</template>

<script>
export default {
    name: 'DataTable',

    props: {
        dataToDisplay: {
            type: Array,
            required: true,
        }
    }
};
</script>

<style lang="scss" scoped>
dl {
    display: flex;
    justify-content: space-between;
    margin: 0;

    &:not(:last-of-type) {
        margin: 0 0 1rem;
    }

    dt {
        font-weight: 500;
        margin: 0;
        line-height: 1.3;
        min-height: 1.3rem;
    }

    dd {
        margin: 0;
        text-align: right;
        max-width: 50%;
    }
}
</style>