<template>
    <div>
        <h2>Change Email Address</h2>
        <p>
            We'll send you a confirmation link to your updated email address.
        </p>
        <div class="content-block">
            <email-input v-model="newEmail" identifier="new-email" placeholder="New Email Address" />
            <email-input v-model="confirmEmail" identifier="confirm-email" placeholder="Confirm New Email Address" />
            <tie-alert v-if="allInputsUpdated && validationMessage">
                {{ validationMessage }}
            </tie-alert>
            <tie-alert v-for="error in errorsOnSubmit" :key="error.title">
                {{ error.title }}
            </tie-alert>
            <div class="button-container">
                <tie-button variation="btn-outline-primary" @click="cancel">
                    Cancel
                </tie-button>
                <tie-button :is-disabled="!canSave" @click="save">
                    Save
                </tie-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import EmailInput from '../email-input/EmailInput';
import TieAlert from '../tie-alert/TieAlert';
import TieButton from '../tie-button/TieButton';

export default {
    name: 'ChangeEmail',

    components: {
        EmailInput,
        TieAlert,
        TieButton,
    },

    data() {
        return {
            newEmail: null,
            confirmEmail: null,
            errorsOnSubmit: [],
        };
    },

    computed: {
        ...mapGetters([
            'getCustomerId',
        ]),

        allInputsUpdated() {
            return Boolean(this.newEmail !== null && this.confirmEmail !== null);
        },

        canSave() {
            return Boolean(!this.validationMessage);
        },

        validationMessage() {
            switch (true) {
                case (!this.newEmail || !this.confirmEmail):
                    return 'Please ensure that you have filled out each of the fields before submitting.';
                case (this.newEmail != this.confirmEmail):
                    return 'Email addresses provided do not match.';
                case (!/^.*@[^.]*\..*$/.test(this.newEmail)):
                    return 'Please enter a valid email address';
                default:
                    return null;
            }
        }
    },

    methods: {
        save() {
            if (!this.canSave) {
                return;
            }

            const data = {
                attributes: {
                    'pending-email': this.newEmail,
                },
                id: this.getCustomerId,
                type: 'customer',
            };

            this.$store.dispatch('changeEmail', data)
                .then(() => {
                    this.$router.push({
                        name: 'SecurityDetails',
                        query: {
                            success: 'Thank you! Please check the inbox of your updated email address for the confirmation link.'
                        },
                    });
                })
                .catch(({errors}) => {
                    this.errorsOnSubmit = errors;
                });
        },

        cancel() {
            this.$router.push({
                name: 'SecurityDetails',
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/container';
@import '../../assets/styles/headings';

p {
    padding: 0 0 0 1.5rem;
    line-height: 1.4;
    margin-bottom: 10px;

    @media (min-width: $lg-screen) {
        padding: 0 0 0 2.5rem;
    }
}

.button-container {
    text-align: right;

    .btn {
        margin: 0 3px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: $lg-screen) {
        text-align: center;
    }
}

.content-block {
    @extend .content-block-padded;
}
</style>