<template>
    <div class="home-container">
        <h1>REGISTER FOR MY EMPORIUM</h1>
        <p>Your password must contain at least 2 digits, one capital letter and be between 8 and 30 characters in length.</p>

        <tie-alert v-if="errors">
            {{ errors }}
        </tie-alert>

        <tie-alert v-if="allInputsUpdated && validationMessage">
            {{ validationMessage }}
        </tie-alert>

        <div class="card access-form">
            <form @keyup.enter="submitRegister">
                <email-input
                    v-model="email"
                    placeholder="Your Email"
                >
                    Email
                </email-input>

                <dob-input
                    v-model="dob"
                />

                <PasswordInput v-model="password" identifier="new-password" placeholder="Your New Password">
                    New Password
                </PasswordInput>

                <PasswordInput v-model="confirmPassword" identifier="confirm-new-password" placeholder="Confirm New Password">
                    Confirm New Password
                </PasswordInput>
            </form>
        </div>

        <div class="btn-container">
            <tie-button
                :is-disabled="!canRegister"
                variation="btn-login"
                @click="submitRegister"
            >
                Register
            </tie-button>
        </div>
    </div>
</template>

<script>


import TieButton from '../../components/tie-button/TieButton';
import TieAlert from '../../components/tie-alert/TieAlert';
import EmailInput from '../../components/email-input/EmailInput';
import PasswordInput from '../../components/password-input/PasswordInput';
import DobInput from '../../components/dob-input/DobInput';
import { mapGetters } from 'vuex';
import Axios from 'axios';

export default {
    name: 'RegisterUser',

    components: {
        TieButton,
        EmailInput,
        DobInput,
        PasswordInput,
        TieAlert
    },

    data() {
        return {
            email: null,
            validDob : false,
            dob: {
                day: null,
                month: null,
                year: null
            },
            password: null,
            confirmPassword: null,
            errors: null,
            requestActive: false
        };
    },

    computed: {
        ...mapGetters([
            'isLoggedIn',
            'getPolicyArray'
        ]),

        canRegister() {
            return Boolean(!this.validationMessage);
        },

        allInputsUpdated() {
            return Boolean(this.password !== null && this.confirmPassword !== null && this.validDob && this.email !== null);
        },

        validationMessage() {
            switch (true) {
                case (!this.password || !this.confirmPassword || !this.validDob || !this.email):
                    return 'Please ensure that you have filled out each of the fields before submitting.';
                case (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))):
                    return 'Email is not valid.';
                case (this.password != this.confirmPassword):
                    return 'Passwords entered do not match.';
                case (this.password.length < 8 || this.password.length > 30):
                    return 'Your password must be between 8 and 30 characters';
                case (!this.password.match(/(.*\d.*){2,}/)):
                    return 'Your password must contain at least two numbers';
                case (!this.password.match(/[A-Z]{1,}/)):
                    return 'Your password must contain at least one capital letter';
                default:
                    return null;
            }
        }
    },

    watch: {
        isLoggedIn(value) {
            if (value) {
                this.$router.push('/dashboard');
            }
        },
        dob() {
            this.validateDob();
        }
    },

    mounted() {
        Axios.get(`registration/${ this.$route.params.id }`
        ).catch(() => {
            this.$router.push({
                path: '/register',
                query: {
                    message: 'invalid-link',
                },
            });
        });
    },

    methods: {
        validateDob() {
            if (this.dob.day && this.dob.month && this.dob.year) {
                this.validDob = true;
            } else {
                this.validDob = false;
            }
        },
        submitRegister() {
            if (this.requestActive || !this.canRegister) {
                return;
            }
            this.requestActive = true;

            let data = {
                attributes: {
                    key: this.$route.params.id,
                    email: this.email,
                    dob: `${this.dob.year}-${this.dob.month}-${this.dob.day}`,
                    password: this.password,
                },
                id: this.$route.params.id,
                type: 'registration'
            };

            Axios.patch(`registration/${ this.$route.params.id }`,
                { data }
            ).then(() => {
                let data = {
                    attributes: {
                        email: this.email,
                        dob: `${this.dob.year}-${this.dob.month}-${this.dob.day}`,
                        password: this.password
                    },
                    type: 'authentication'
                };
                this.requestActive = false;

                this.$store.dispatch('login', data);

            }).catch((error) => {
                if (error.data && error.data.errors) {
                    this.errors = error.data.errors[0].title;
                } else {
                    this.errors = 'Unexpected Failure';
                    this.$rollbar.error('Unexpected Failure - Registration');
                }
                this.requestActive = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";
</style>