<template>
    <footer class="footer--copy">
        <p>
            Lifestyle Policy Limited and Entertainment & Leisure Insurance Services Limited are appointed representatives
            of The Equine and Livestock Insurance Company Limited. The Insurance Emporium <span class="reg_symbol">®</span>
            is a trading name of The Equine and Livestock Insurance Company Limited (registered in England and Wales no: 294940)
            which is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority
            and the Prudential Regulation Authority no: 202748.
        </p>
    </footer>
</template>

<script>
export default {
    name: 'FooterCopyright'
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

footer {
    font-family: $default-fonts;
    background: $footer-background-color;
    padding: 0 10px 0 10px;
    color: $footer-text-color;
    text-align: center;

    p {
        font-size: 12px;
        line-height: 1.4;
        min-width: 300px;
        max-width: 800px;
        text-align: center;
        display: inline-block;
        margin-bottom: 0.6rem;
    }

    .reg_symbol {
        font-size: 30px;
        position: relative;
        top: 12px;
        right: 2px;
        line-height: 0;
    }
}
</style>