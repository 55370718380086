let storedAuthToken = localStorage.getItem('authToken');
let customerId = localStorage.getItem('customerId');
let policies = JSON.parse(localStorage.getItem('policies'));
let policyArray = JSON.parse(localStorage.getItem('policyArray'));

export default {
    prevPage: null,
    isSidebarOpen: false,
    status: [],
    isLoggedIn: Boolean(storedAuthToken),
    authToken: storedAuthToken,
    policyArray: policyArray || [],
    policies: policies || [],
    upgradeSelection: {},
    upgradeOptions: {},
    upgrade: {},
    customer: {
        id: customerId || null,
        attributes: {},
    },
    timer: null
};