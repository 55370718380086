<template>
    <div class="home-container">
        <h1>LOG IN TO<br> MY EMPORIUM</h1>

        <tie-alert v-if="successMessage" variant="success">
            {{ successMessage }}
        </tie-alert>

        <tie-alert v-if="errorMessage.length > 0">
            {{ errorMessage }}
        </tie-alert>

        <tie-alert v-for="error in errors" :key="error.index">
            {{ error.title }}
        </tie-alert>

        <div class="card access-form">
            <form @keyup.enter="submitLogin">
                <email-input
                    v-model="email"
                >
                    Email
                </email-input>

                <dob-input
                    v-model="dob"
                />

                <password-input
                    v-model="password"
                >
                    Password
                </password-input>
            </form>
            <div class="forgot-link">
                <router-link to="/forgot-password">
                    Forgot password?
                </router-link>
            </div>
        </div>

        <div class="btn-container">
            <tie-button
                :is-disabled="!canLogin"
                variation="btn-login"
                @click="submitLogin"
            >
                Log in
            </tie-button>
            <p>
                No account?
                <router-link to="/register">
                    Register Here
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import TieButton from '../../components/tie-button/TieButton';
import TieAlert from '../../components/tie-alert/TieAlert';
import EmailInput from '../../components/email-input/EmailInput';
import PasswordInput from '../../components/password-input/PasswordInput';
import DobInput from '../../components/dob-input/DobInput';
import { mapGetters } from 'vuex';

export default {
    name: 'LoginPage',

    components: {
        TieButton,
        EmailInput,
        DobInput,
        PasswordInput,
        TieAlert
    },

    data() {
        return {
            email: '',
            validEmail: false,
            validDob : false,
            dob: {
                day: null,
                month: null,
                year: null
            },
            password: '',
            errors: [],
            requestActive: false
        };
    },

    computed: {
        ...mapGetters([
            'getPolicyArray',
            'isLoggedIn'
        ]),

        canLogin() {
            return (this.validEmail && this.validDob && this.password.length > 0);
        },

        errorMessage() {
            return this.$route.query.message === 'error-email' ? 'There has been an issue confirming your email address. Please login and check your email status.' : false;
        },

        successMessage() {
            if (this.errors.length > 0) {
                return null;
            }

            let messageParam = this.$route.query.message;
            switch(messageParam) {
                case 'confirm-email':
                    return 'Your email has been updated! You can now log in!';
                case 'forgot-password':
                    return 'Your password has been updated! You can now log in!';
                case 'register-successful':
                    return 'Thank you, your registration is completed! You can now log in using the details you provided!';
                case 'register-email':
                    return 'If the email address is associated with an Emporium account, an email will be sent with a link to complete your registration.';
                case 'logout':
                    return 'Your account has been logged out.';
                case 'timed-out':
                    return 'Your account has been logged out due to inactivity.';
                default:
                    return null;
            }
        }
    },

    watch: {
        isLoggedIn(value) {
            if (value) {
                this.$router.push('/dashboard');
            }
        },
        email() {
            this.validateEmail();
        },
        dob() {
            this.validateDob();
        }
    },

    methods: {
        validateEmail() {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.email)) {
                this.validEmail = true;
            } else {
                this.validEmail = false;
            }
        },

        validateDob() {
            if (this.dob.day && this.dob.month && this.dob.year) {
                this.validDob = true;
            } else {
                this.validDob = false;
            }
        },

        submitLogin() {
            if (this.requestActive || !this.canLogin) {
                return;
            }
            this.requestActive = true;

            let data = {
                attributes: {
                    email: this.email,
                    dob: `${this.dob.year}-${this.dob.month}-${this.dob.day}`,
                    password: this.password
                },
                type: 'authentication'
            };

            this.$store.dispatch('login', data).then(async () => {
                this.requestActive = false;
                await this.$store.dispatch('getAllPolicys', this.getPolicyArray);
            }).catch((errors) => {
                this.errors = errors;
                this.requestActive = false;
            });

        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";
</style>
