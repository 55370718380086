<template>
    <div v-if="getPolicy && getPolicy.attributes" class="container-wrapper">
        <div class="container">
            <main>
                <h2>Upgrade your policy</h2>
                <div class="content-block">
                    <p v-if="getPolicy.attributes['pet-detail']['animal-type'] === 'D'">
                        In 2018, <strong>82.6%</strong> of dog vet claims were for illnesses. Approximately <strong>1 in 10</strong> were for <strong>Growths, Cysts and Tumours</strong>, with an average payment being <strong>£583</strong> and the highest total payment being <strong>£7,895*</strong>.
                    </p>
                    <p v-else-if="getPolicy.attributes['pet-detail']['animal-type'] === 'C'">
                        In 2018, <strong>73.0%</strong> of cat claims were for illnesses. Approximately <strong>1 in 10</strong> vet fees cat claims were for <strong>Urinary System issues</strong>, with the average claim being <strong>£484</strong> and the highest claim being <strong>£5,238*</strong>.
                    </p>
                    <p v-else>
                        In 2018, <strong>1 in 10</strong> of our pet insurance claims* received were for <strong>growths, cysts and tumours,</strong> which can cost up to <strong>£7,591.11.</strong>
                    </p>
                    <p>Did you know that <strong>{{ getPolicy.attributes.insured }}</strong> {{ isCoveredForIllness ? 'is not covered for illnesses' : 'is only covered for illnesses' }} such as these {{ !isCoveredForIllness ? 'up to ' + getPolicy.attributes['vets-fees'] : '' }} {{ isTimeLimited ? 'each policy term?' : 'under your current policy?' }}</p>
                    <p><strong>Find out how you could give {{ getPolicy.attributes.insured }} more below!</strong></p>
                </div>

                <hr>

                <h3>Your Upgrade Opportunities... </h3>

                <div class="upgrade-options">
                    <PolicyUpgradeOption
                        v-for="option in getUpgradeOptions.data"
                        :key="option.id"
                        :policy="getPolicy"
                        :option="option"
                    />
                    <tie-alert v-if="upgradeOptionError && upgradeOptionError.length > 0">
                        {{ upgradeOptionError }}
                    </tie-alert>
                </div>

                <hr>

                <h2>Important Information to consider about your upgrade</h2>
                <div class="content-block">
                    <ul class="bullets-cta">
                        <li>Your premium and excess is reviewed at least once a year and may be affected by the age of your pet or a change in your address, our own claims experience and information from external sources.</li>
                    </ul>
                </div>
                <p class="asterisk-text">
                    * Statistics generated from all The Insurance Emporium approved pet claims in 2018
                </p>
            </main>
        </div>
    </div>
    <div v-else class="container-wrapper error-container">
        <tie-alert>
            There has been an issue retrieving your policy at this time. please try again later.
        </tie-alert>
    </div>
</template>

<script>
import PolicyUpgradeOption from '../../../../../components/policies/PolicyUpgrade/PolicyUpgradeOption';
import TieAlert from '../../../../../components/tie-alert/TieAlert';
import { mapGetters } from 'vuex';

export default {
    name: 'MyPolicyUpgrade',

    components: {
        PolicyUpgradeOption,
        TieAlert
    },

    data() {
        return {
            upgradeOptionError: null
        };
    },

    computed: {
        ...mapGetters([
            'getUpgradeOptions'
        ]),

        getPolicy() {
            return this.$store.getters.getPolicy(this.$route.params.id);
        },

        isCoveredForIllness() {
            return this.getPolicy.attributes.title === 'Accident Only' || this.getPolicy.attributes.title === 'Public Liability only';
        },

        isTimeLimited() {
            return this.getPolicy.attributes.title.indexOf('Time Limited') > -1;
        },

        policyTypeGroup() {
            let stringCover, stringOptions;
            switch(this.getPolicy.attributes['policy-type-group']) {
                case 'TIMELIMITED':
                    stringOptions = 'We have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'only covers vet’s fees for accidents and illnesses up to 365 days from the date each injury or illness first showed clinical signs?';
                    break;
                case 'MAXBENEFIT':
                    stringOptions = 'We have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'only covers vet’s fees for accidents and illnesses up to the maximum amount available irrespective of how long it takes to reach it?';
                    break;
                case 'ACCIDENTONLY':
                    stringOptions = 'We have other options that could provide Vet’s Fees cover for accidents and illnesses!';
                    stringCover = 'only provides cover for Vet’s Fees following an injury caused by an accident?';
                    break;
                case 'LIFETIME':
                    stringOptions = 'But we have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'provides vet’s fees cover for accidents and illnesses throughout the life of your pet?';
                    break;
                default: // policy-type-group: PUBLICLIABILITY
                    stringOptions = 'We have other options that could provide Vet’s Fees cover for accidents and illnesses!';
                    stringCover = 'does not include cover for Vet’s Fees?';
                    break;
            }
            return [stringCover, stringOptions];
        },
    },

    mounted() {
        this.$store.dispatch('getPolicy', this.$route.params.id);
        this.$store.dispatch('getPolicyUpgradeOptions', [this.$route.params.id, this.$route.query.filter]).catch((resp) => {
            this.upgradeOptionError = resp.errors[0].title;
        });
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/container";
@import "../../../../../assets/styles/policy-upgrade";

.error-container {
    align-items: flex-start;
}

hr {
    width: 50%;
    margin: 10px 25%;
}

.asterisk-text {
    margin-top: 1rem;
    color: #B5B5B5;
}

.bullets-cta {
    li {
        list-style-type: none;
        list-style-position: outside;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    li::before {
        content: "•";
        color: $danger;
        float: left;
        margin-right: -10em;
        display: block;
        transform: translateX(-10px);
    }
}
</style>