import router from '../router';

export default {
    toggleSidebar(state) {
        state.isSidebarOpen = !state.isSidebarOpen;
    },

    login(state, data) {
        const authToken = data.id;
        const customerId = data.relationships.customer.data.id;
        const policies = data.relationships.policy.data;
        const policyArray = policies.map((policy) => {
            return policy.id;
        });
        // Sort policyArray (List of ID strings) - Ascending
        policyArray.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

        state.authToken = authToken;
        state.isLoggedIn = true;
        state.customer.id = customerId;
        state.policyArray = policyArray;
        state.policies = policies;

        localStorage.setItem('supplementaryKey', data.attributes['supplementary-key']);
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('customerId', customerId);
        localStorage.setItem('policyArray', JSON.stringify(policyArray));
        localStorage.setItem('policies', JSON.stringify(policies));
    },

    logout(state) {
        state.authToken = null;
        state.isLoggedIn = false;
        state.policies = [];
        state.policyArray = [];
        state.customer = {
            id: null,
            attributes: {}
        };
        state.prevPage = null;
        clearTimeout(state.timer);
        state.timer = null;
        state.isSidebarOpen = false;
        state.upgradeSelection = {};
        state.upgradeOptions = {};
        state.upgrade = {};

        localStorage.clear();
    },

    restartTimer(state) {
        clearTimeout(state.timer);
        state.timer = setTimeout(() => {
            this.dispatch('logout').then(() => {
                router.push('/?message=timed-out');
            });
        }, 1800000);
    },

    setUpgradeOptions(state, data) {
        state.upgradeOptions = data;
    },

    setUpgradeSelection(state, data) {
        state.upgradeOptions = {};
        state.upgradeSelection = data;
    },

    setUpgrade(state, data) {
        state.upgrade = data;
    },

    addPolicy(state, { data }) {
        let arrayWithoutRecord = state.policies.filter(c => c.id !== data.data.id);
        arrayWithoutRecord.push(data.data);
        state.policies = arrayWithoutRecord;
        localStorage.setItem('policies', JSON.stringify(state.policies));
    },

    setPrevPage(state, from) {
        state.prevPage = from;
    },

    setCustomerAttributes(state, attributes) {
        state.customer.attributes = attributes;
    }
};