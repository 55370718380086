<template>
    <label v-if="label.length > 0" :for="identifier">{{ label }}</label>
    <input
        :id="identifier"
        :value="modelValue"
        name="text-input"
        autocomplete="off"
        :placeholder="placeholder"
        class="text-input"
        :class="{ error: error && error.length > 0 }"
        type="text"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';

export default {
    name: 'TextInput',

    components: {
        TieAlert
    },

    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },

        identifier: {
            type: String,
            required: false,
            default: 'text-input',
        },

        error: {
            type: String,
            required: false,
            default: ''
        },

        placeholder: {
            type: String,
            required: false,
            default: '',
        },

        'modelValue': {
            type: String,
            required: false,
            default: '',
        }
    },

    emits: ['update:modelValue'],
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/forms";

label {
    font-weight: bold;
    margin: 14px 0 3px;
    position: relative;
}

</style>
