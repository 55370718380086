<template>
    <div
        id="burger"
        :class="{ 'active' : open }"
        @click.prevent="toggleMenu"
    >
        <slot>
            <button
                type="button"
                class="burger-button"
                title="Menu"
            >
                <span class="burger-bar burger-bar--1" />
                <span class="burger-bar burger-bar--2" />
                <span class="burger-bar burger-bar--3" />
            </button>
        </slot>
        <span>Menu</span>
    </div>
</template>

<script>
export default {
    name: 'HamburgerMenu',
    computed: {
        open() {
            return this.$store.state.isSidebarOpen;
        }
    },
    methods: {
        toggleMenu() {
            this.$store.commit('toggleSidebar');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.hidden {
    visibility: hidden;
}

@media screen and (min-width: 1199px) {
    #burger {
        display: none;
    }
}

#burger {
    float: right;

    > span {
        font-size: 12px;
        font-family: sans-serif;
        font-weight: bold;
        left: 6px;
        position: relative;
        color: $sidebar-background-color;
    }

    .burger-button {
        position: relative;
        padding: 20px;
        display: block;
        z-index: 999;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        pointer-events: all;
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        cursor: pointer;

        &:focus {
            outline: 0;
        }
    }

    .burger-bar {
        background-color: $sidebar-background-color;
        position: absolute;
        top: 50%;
        right: 6px;
        left: 6px;
        height: 6px;
        width: 32px;
        margin-top: -1px;
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar--1 {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    .burger-bar--3 {
        transform: translateY(10px);
    }

    &.active {
        .burger-bar--1 {
            transform: rotate(45deg);
        }

        .burger-bar--2 {
            opacity: 0;
        }

        .burger-bar--3 {
            transform: rotate(-45deg);
        }
    }
}
</style>