<template>
    <label :for="identifier">
        <slot />
        <div
            class="eye-icon password-icon"
            :class="{ 'show' : type === 'text' }"
            @click="showPassword"
        >
            <svg>
                <use xlink:href="@/assets/images/sprite.svg#eye" />
            </svg>
        </div>
        <input
            :id="identifier"
            :value="modelValue"
            name="password"
            autocomplete="off"
            :placeholder="placeholder"
            :type="type"
            @input="$emit('update:modelValue', $event.target.value)"
        >
    </label>
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';

export default {
    name: 'PasswordInput',

    components: {
        TieAlert
    },

    props: {
        identifier: {
            type: String,
            required: false,
            default: 'password'
        },

        modelValue: {
            type: String,
            required: false,
            default: '',
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Your Password',
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            error: '',
            type: 'password'
        };
    },

    methods: {
        validatePassword(newValue) {
            if (newValue.length > 0) {
                this.error = '';
                return true;
            } else {
                this.error = 'Please enter a password';
                return false;
            }
        },

        showPassword() {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/forms";

</style>
