<template>
    <div
        v-if="option && option.attributes"
        class="upgrade-option"
        :class="option.attributes['current-option'] ? ' upgrade-option--current' : ''"
        :data-cover="option.attributes.coverLevel"
        :data-scheme="option.attributes.productType"
    >
        <div class="upgrade-option__sash">
            <p v-if="option.attributes['current-option']">
                Your current policy
            </p>
        </div>
        <div class="upgrade-option__details">
            <div class="upgrade-option__price">
                <span class="upgrade-price" :class="option.attributes['current-option'] ? ' true' : ' false'">
                    <div v-if="option.attributes['current-option']">
                        £{{ policy.attributes.premium }}
                    </div>
                    <div v-else>
                        <small>+</small>£{{ isAnnual ? option.attributes['extra-per-year'] : option.attributes['extra-per-month'] }}
                    </div>
                </span>
                <span class="upgrade-frequency">{{ getPaymentFrequency }}</span>
            </div>
            <ul class="upgrade-benefits" :class="option.attributes['current-option'] ? ' true' : ' false'">
                <li v-for="benefit in filteredBenefits" :key="benefit.title" :data-title="benefit.title">
                    {{ benefit.covered ? '£' + benefit.options[0].value : '' }}
                    <img
                        v-if="benefit.covered === false"
                        class="logo"
                        src="@/assets/images/icon_cross.svg"
                        alt=""
                    >
                </li>
            </ul>
            <div class="upgrade-all-benefits">
                <p @click="showBenefitModal = true">
                    See all benefits <svg alt="" class="icon-info small"><use xlink:href="@/assets/images/sprite.svg#info-icon" /></svg>
                </p>
            </div>
            <div class="upgrade-new-premium">
                <p v-if="option.attributes['current-option']">
                    &nbsp;
                </p>
                <p v-if="!option.attributes['current-option']">
                    <strong>NEW PREMIUM £{{ option.attributes.premium }}</strong>
                </p>
                <span v-if="!option.attributes['current-option']" class="upgrade-frequency">{{ getPaymentFrequency }}</span>
                <span v-if="!option.attributes['current-option']" class="upgrade-suffix">(inc. IPT)</span>
            </div>
            <div class="upgrade-excess">
                <p>
                    <span>Please see here for details of cover and applicable excesses</span>
                    <span>
                        <svg alt="" class="icon-info small" @click="showExcessModal = true"><use xlink:href="@/assets/images/sprite.svg#info-icon" /></svg>
                    </span>
                </p>
            </div>
            <div class="upgrade-button">
                <router-link v-if="!option.attributes['current-option']" :to="`/dashboard/my-policies/${ policy.id }/upgrade/${ option.id }`">
                    <tie-Button variation="btn-login">
                        Upgrade
                    </tie-Button>
                </router-link>
            </div>
            <div>
                <h6>{{ option.attributes['policy-type-group'] }}</h6>
                <br>
                <p>{{ option.attributes['cover-level'] }}</p>
            </div>
        </div>

        <Teleport to="body">
            <tie-modal :show="showExcessModal" @close="showExcessModal = false">
                <template #header>
                    {{ option.attributes['title'] }}
                </template>
                <template #body>
                    <p>{{ getExcessIntroduction }}</p>
                    <p v-if="option.attributes['vets-fees']">
                        Vet's Fees excess - {{ excessText(option.attributes['vets-fees']) }}
                    </p>
                    <p v-if="option.attributes['public-liability']">
                        Public Liability excess - {{ excessText(option.attributes['public-liability']) }}
                    </p>
                </template>
            </tie-modal>
        </Teleport>

        <Teleport to="body">
            <tie-modal :show="showBenefitModal" @close="showBenefitModal = false">
                <template #header>
                    {{ option.attributes['title'] }}
                </template>
                <template #body>
                    <div class="benefit-breakdown__table">
                        <table>
                            <tr v-for="benefit in benefitTableData" :key="benefit.sequence">
                                <th scope="row">
                                    {{ benefit.title }}
                                </th>
                                <td>{{ benefit.value }}</td>
                            </tr>
                        </table>
                    </div>
                </template>
            </tie-modal>
        </Teleport>
    </div>
</template>

<script>
import TieButton from '../../../components/tie-button/TieButton';
import TieModal from '../../../components/tie-modal/TieModal';

export default {
    name: 'PolicyUpgradeOption',

    components: {
        TieButton,
        TieModal
    },

    props: {
        policy: {
            type: Object,
            required: true
        },
        option: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showExcessModal: false,
            showBenefitModal: false
        };
    },

    computed: {
        isAnnual() {
            return this.policy.attributes['payment-frequency'] === 'A';
        },

        getPaymentFrequency() {
            return this.policy.attributes['payment-term-frequency'];
        },

        getExcessIntroduction() {
            var string;
            switch(this.option.attributes['policy-type-group']) {
                case 'Time Limited':
                    string = 'The policy provides cover for your pet for veterinary treatment for up to 365 days from the date each injury, illness or condition first showed clinical signs.';
                    break;
                case 'Maximum Benefit':
                    string = 'The policy provides vet’s fees cover for each injury, illness or condition to the maximum amount available irrespective of the time taken to reach it.';
                    break;
                case 'Accident Only':
                    string = 'The policy provides cover for veterinary treatment of your pet following an injury caused by an accident.';
                    break;
                case 'Lifetime':
                    string = 'The policy provides cover for veterinary treatment throughout the life of your pet as long as the premiums are kept up to date.';
                    break;
                case 'Public Liability':
                    string = 'The policy provides cover for your legal liability to others for compensation and costs arising out of your ownership of your dog.';
                    break;
                default:
                    string = null;
                    break;
            }
            return string;
        },

        filteredBenefits() {
            return this.option.attributes.benefits.filter(this.filterBenefits);
        },

        benefitTableData() {
            return this.option.attributes.benefits.map((benefit) => {
                return {
                    covered: benefit.covered,
                    title: benefit.title,
                    value: benefit.options.length > 0 ? '£' + benefit.options[0].value : null,
                };
            });
        }
    },

    methods: {
        filterBenefits(benefit) {
            var showBenefitsCurrentArray = ['Vet\'s Fees', 'Personal Accident', 'Death Of Pet', 'Public Liability', 'Accidental Damage', 'Holiday Cancellation'];
            var showBenefitsArray = ['Vet\'s Fees', 'Personal Accident', 'Death Of Pet', 'Public Liability'];
            return this.option.attributes['current-option'] ? showBenefitsCurrentArray.indexOf(benefit.title) > -1 : showBenefitsArray.indexOf(benefit.title) > -1;
        },
        excessText(benefit) {
            return '£' + benefit.options[0].excess + ' ' + benefit.options[0].excessText + ' ' +  benefit.options[0].text;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/policy-upgrade";
</style>