<template>
    <div class="register-container home-container">
        <h1>REGISTER FOR MY EMPORIUM</h1>

        <div class="card access-form">
            <p>Thank you for entering your details. Check your inbox for an email from hello@emporium.co.uk with the next steps to complete your registration!</p>
            Click <a href="https://www.theinsuranceemporium.co.uk/">here</a> to go to The Insurance Emporium.
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterComplete',
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";

.register-container {
    text-align: center;
}

.card {
    margin-top: 10px;
    margin-bottom: 1.2rem;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    background: #FEFEFE;
    padding: 1.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    -webkit-animation: fade-in-0d91fc38 0.3s forwards cubic-bezier(0.47, 0, 0.75, 0.72);
    animation: fade-in-0d91fc38 0.3s forwards cubic-bezier(0.47, 0, 0.75, 0.72);
}

.home-container {
    margin: 1rem auto;
    max-width: 800px;
    min-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1 auto;
}
</style>