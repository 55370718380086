<template>
    <div
        v-if="getPolicy && getPolicy.attributes"
        class="container-wrapper"
    >
        <div class="container">
            <main>
                <back-button />
                <section class="policy-information">
                    <div class="error hide">
                        There has been an issue retrieving your policy at this time. please try again later.
                    </div>
                    <div>
                        <h1>
                            My <span>{{ getPolicy.attributes.product }}</span> Policy
                        </h1>

                        <data-table :data-to-display="dataToDisplay" />

                        <div v-if="getPolicy.attributes['upgrade']" class="upgrade-banner">
                            <h1>
                                Upgrade Your Cover!
                            </h1>
                            <div class="upgrade-banner__content">
                                <div class="upgrade-banner__text">
                                    <p class="hide-for-small-only">
                                        Did you know that {{ getPolicy.attributes.insured }}'s policy <strong>{{ bannerPolicyCover[0] }}</strong>
                                    </p>
                                    <p>{{ bannerPolicyCover[1] }}</p>
                                    <p>Give {{ getPolicy.attributes.insured }} more from just <strong>£{{ getPolicy.attributes['upgrade']['new-premium'] }}</strong> {{ getPaymentFrequency }}*!</p>
                                    <tie-Button class="button" @click="showModal = true">
                                        FIND OUT MORE
                                    </tie-Button>
                                </div>
                            </div>
                        </div>

                        <h2 class="extra">
                            My Documents
                        </h2>

                        <document-list :documents="getPolicy.attributes.documents" />

                        <Teleport to="body">
                            <tie-modal v-if="getPolicy.attributes['upgrade']" :show="showModal" @close="showModal = false">
                                <template #header>
                                    What would you like to cover {{ getPolicy.attributes.insured }} for?
                                </template>
                                <template #body>
                                    <ul v-if="getUpgradeSelection && getUpgradeSelection.data" class="policy-selection">
                                        <li v-for="selection in getUpgradeSelection.data" :key="selection.id" class="policy-selection__option">
                                            <div class="policy-selection__option__title">
                                                <input
                                                    :id="'upgrade--' + selection.id"
                                                    v-model="filterSelected"
                                                    name="upgrade"
                                                    type="radio"
                                                    :value="selection.id"
                                                >
                                                <label :for="'upgrade--' + selection.id" class="is-radio">
                                                    {{ selection.attributes.title }}
                                                    <span class="policy-type-group">
                                                        {{ availableUpgradeTypes(selection.attributes['policy-type-group'], selection.attributes['cover-type']) }}
                                                    </span>
                                                </label>

                                                <label
                                                    class="upgrade-description-trigger"
                                                    :for="'upgrade-description--' + selection.id"
                                                >
                                                    <span><svg alt="" class="icon-info"><use xlink:href="@/assets/images/sprite.svg#info-icon" /></svg></span>
                                                </label>
                                            </div>
                                            <input
                                                :id="'upgrade-description--' + selection.id"
                                                name="upgrade-description"
                                                type="radio"
                                            >
                                            <div class="upgrade-description">
                                                <h6>{{ selection.attributes['policy-type-group'] }}</h6>
                                                <p>{{ selection.attributes['description'] }}</p>
                                            </div>
                                        </li>

                                        <li class="policy-selection__option">
                                            <input
                                                id="upgrade--none"
                                                v-model="filterSelected"
                                                name="upgrade"
                                                type="radio"
                                                value="none"
                                            >
                                            <label for="upgrade--none" class="is-radio">I'm not sure, show me all the options!</label>
                                        </li>
                                    </ul>
                                </template>
                                <template #footer>
                                    <router-link :to="`/dashboard/my-policies/${ getPolicy.id }/upgrade?filter=${ filterSelected }`">
                                        <tie-Button class="button" :disabled="!filterSelected" @click="showModal = false">
                                            Next
                                        </tie-Button>
                                    </router-link>
                                </template>
                            </tie-modal>
                        </Teleport>
                    </div>
                </section>
            </main>
        </div>
    </div>
    <div v-else class="container-wrapper error-container">
        <tie-alert v-if="error.length > 0">
            {{ error }}
        </tie-alert>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import BackButton from '../../../../components/back-button/BackButton';
import TieButton from '../../../../components/tie-button/TieButton';
import TieModal from '../../../../components/tie-modal/TieModal';
import DataTable from '../../../../components/policies/PolicyDetails/DataTable';
import DocumentList from '../../../../components/policies/PolicyDetails/DocumentList';
import TieAlert from '../../../../components/tie-alert/TieAlert';

export default {
    name: 'PolicyDetails',

    components: {
        DocumentList,
        BackButton,
        DataTable,
        TieButton,
        TieModal,
        TieAlert
    },

    data() {
        return {
            error: 'There has been an issue retrieving your policy at this time. please try again later.',
            showModal: false,
            filterSelected: null
        };
    },

    computed: {
        ...mapGetters([
            'getUpgradeSelection'
        ]),

        getPolicy() {
            return this.$store.getters.getPolicy(this.$route.params.id);
        },

        getPaymentFrequency() {
            return this.getPolicy.attributes['payment-term-frequency'];
        },

        bannerPolicyCover() {
            let stringCover, stringOptions;
            switch(this.getPolicy.attributes['policy-type-group']) {
                case 'TIMELIMITED':
                    stringOptions = 'We have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'only covers vet’s fees for accidents and illnesses up to 365 days from the date each injury or illness first showed clinical signs?';
                    break;
                case 'MAXBENEFIT':
                    stringOptions = 'We have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'only covers vet’s fees for accidents and illnesses up to the maximum amount available irrespective of how long it takes to reach it?';
                    break;
                case 'ACCIDENTONLY':
                    stringOptions = 'We have other options that could provide Vet’s Fees cover for accidents and illnesses!';
                    stringCover = 'only provides cover for Vet’s Fees following an injury caused by an accident?';
                    break;
                case 'LIFETIME':
                    stringOptions = 'But we have other options that could enhance your vet’s fees cover for accidents and illnesses!';
                    stringCover = 'provides vet’s fees cover for accidents and illnesses throughout the life of your pet?';
                    break;
                default: // policy-type-group: PUBLICLIABILITY
                    stringOptions = 'We have other options that could provide Vet’s Fees cover for accidents and illnesses!';
                    stringCover = 'does not include cover for Vet’s Fees?';
                    break;
            }
            return [stringCover, stringOptions];
        },

        dataToDisplay() {
            if (!this.getPolicy) {
                return [];
            }
            return [
                {
                    title: 'Policy Number',
                    value: this.$route.params.id
                },
                {
                    title: 'Policy Name',
                    value: this.getPolicy.attributes.insured
                },
                {
                    title: 'Policy Type',
                    value: this.getPolicy.attributes.title
                },
                {
                    title: this.getPolicy.attributes['payment-term-frequency'].includes('Annual') ? 'Policy Duration' : 'Policy Term',
                    value: this.getPolicy.attributes['payment-term-frequency']
                },
                {
                    title: 'Premium',
                    value: `£${ this.getPolicy.attributes.premium }`
                },
                {
                    title: 'Start Date',
                    value: this.dateTime(this.getPolicy.attributes['start-date'])
                },
                {
                    title: this.getPolicy.attributes['payment-frequency'] === 'A' ? 'Renewal Date' : 'Review Date',
                    value: this.dateTime(this.getPolicy.attributes['renewal-date'])
                },
                {
                    title: 'Automatic Renewal',
                    value: this.getPolicy.attributes['auto-renewal'] ? 'Yes' : 'No',
                    isDisplayed: !['PET', 'HORSE'].includes(this.getPolicy.attributes['product'])
                },
                {
                    title: '',
                    value: this.getPolicy.attributes['auto-renewal'] ? `Your policy will automatically renew at 12:01am on ${this.dateTime(this.getPolicy.attributes['renewal-date'])}. If you wish to opt out of automatic renewal, please fill out our <a href="https://www.theinsuranceemporium.co.uk/existing-customers/manage-your-account/auto-renewal-opt-out" target="_blank">form</a>` : `Your policy will end at 12:01am on ${this.dateTime(this.getPolicy.attributes['renewal-date'])}.`,
                    isDisplayed: !['PET', 'HORSE'].includes(this.getPolicy.attributes['product'])
                },
            ];
        },
    },

    mounted() {
        this.$store.dispatch('getPolicy', this.$route.params.id).then(() => {
            if(this.getPolicy.attributes['upgrade']) {
                this.$store.dispatch('getPolicyUpgradeSelection', this.$route.params.id);
            }
        });
    },

    methods: {
        dateTime(value) {
            return moment(value).format('DD-MM-YYYY');
        },
        availableUpgradeTypes(policygroup, coverlevel) {
            if (coverlevel) {
                let coverLevels = [];
                coverlevel.forEach(function(coverLevel) {
                    var formattedCoverLevel = coverLevel.replace(/\w\S*/g, function(txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                    coverLevels.push(formattedCoverLevel);
                });
                return policygroup + ' ' + coverLevels.join(' or ');
            }
            return policygroup;
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/container";
@import "../../../../assets/styles/forms";

.error-container {
    align-items: flex-start;
}

.hide {
    display: none;
}

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

.extra {
    padding-top: 2px;
}

.upgrade-banner {
    background-color: $primary-color;
    color: white;
    margin-bottom: 1.2rem;
    position: relative;
    animation: fade-in-0d91fc38 0.3s forwards cubic-bezier(0.47, 0, 0.75, 0.72);
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    h1 {
        color: white;
    }

    &__content {
        padding-left: 1.5rem;
        display: flex;
    }

    &__text {
        padding-bottom: 78px;

        > p:last-of-type {
            margin-bottom: 0;
        }
    }

    img {
        width: 100%;
    }

    button {
        margin: 0;
        border: 2px solid white;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.policy-selection {
    list-style: none;

    .policy-selection__option {
        &__title {
            display: flex;
            align-items: baseline;
        }

        input[type="radio"] + label.is-radio {
            margin-right: 0;
        }
    }

    .policy-type-group {
        font-style: italic;
        font-size: 0.75rem;
        display: block;
    }

    .upgrade-description {
        background-color: #E7F4F5;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 12px;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        font-size: 14px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    input[name="upgrade-description"]:checked + div,
    input[name="upgrade-description"] + .upgrade-description {
        display: none;
    }

    input[name="upgrade-description"]:checked + .upgrade-description {
        display: block;
    }

    .upgrade-description-trigger {
        display: inline-block;
        cursor: pointer;
        max-width: 25%;
    }

    .icon-info {
        fill: $primary;
        width: 17px;
        height: 17px;
        vertical-align: middle;

        &.secondary {
            fill: white;
        }

        &.small {
            width: 12px;
            height: 12px;
        }
    }
}
</style>
