<template>
    <div class="content-block documents-list">
        <div v-if="documents.length < 1">
            Sorry we could not retrieve your documents at this time. Please try again later.
        </div>
        <document-list-item
            v-for="document in documents"
            :key="document.id"
            :api="document.api"
            :document="document"
        />
    </div>
</template>

<script>
import DocumentListItem from './DocumentListItem';

export default {
    name: 'DocumentList',

    components: {
        DocumentListItem
    },

    props: {
        documents: {
            type: Array,
            required: true,
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/container";

</style>