export default {
    getCustomer(state) {
        if (!Object.keys(state.customer.attributes).length) {
            return null;
        }
        return state.customer;
    },

    getPolicies(state) {
        return state.policies;
    },

    getPolicyArray(state) {
        return state.policyArray;
    },

    getAuthToken(state) {
        return state.authToken;
    },

    getUpgradeSelection(state) {
        return state.upgradeSelection;
    },

    getUpgradeOptions(state) {
        return state.upgradeOptions;
    },

    getUpgrade(state) {
        return state.upgrade;
    },

    isLoggedIn(state) {
        return state.isLoggedIn;
    },

    getCustomerId(state) {
        return state.customer.id;
    },

    getPolicy: (state) => (policyId) => {
        if (!state.policies) {
            return null;
        }
        return state.policies.find(policy => {
            return policy.id === policyId;
        });
    },

    prevPage(state) {
        return state.prevPage;
    }
};