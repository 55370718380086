import Axios from 'axios';
import router from '../router/';
import store from '../store/index';
import { trackEvent } from '../utilities/analytics';

const apiUrl = process.env.VUE_APP_API_URL;

Axios.defaults.baseURL = `${ apiUrl }/api/v1/`;
Axios.defaults.withCredentials = true;
Axios.defaults.headers = {
    'Access-Control-Allow-Origin': apiUrl,
    'Content-Type': 'application/json',
};
Axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error?.response && error.response.status === 403 && !(error.response.config.url.includes('authentication'))){
        // On 403 log user out and navigate to timeout message
        if (localStorage.getItem('authToken')) {
            store.dispatch('logout').then(() => {
                router.push('/?message=timed-out');
            });
        }
    }
    return Promise.reject(error?.response);
});

export default {
    api_status() {
        return new Promise((resolve, reject) => {
            if (process.env.VUE_APP_FORCE_MAINTENANCE === 'true') {
                reject();
            }
            Axios.get('status').then(resp => {
                if (resp && resp.data) {
                    resolve(resp.data);
                } else {
                    reject();
                }
            }).catch(() => {
                reject();
            });
        });
    },

    login({commit}, data) {
        return new Promise((resolve, reject) => {
            Axios.post('authentication', {
                data: data,
            }, {
                withCredentials: true,
            })
                .then((response) => {
                    commit('login', response.data.data);

                    // Track login event
                    trackEvent({
                        event: 'login',
                        eventCategory: 'User',
                        eventAction: 'Login',
                        eventLabel: 'User Login',
                    });

                    // Resolve after trackEvent is done
                    resolve(response.data.data);

                }).catch((error) => {
                    if (error && error.data) {
                        reject(error.data.errors);
                    } else {
                        reject([{title: 'Unexpected Error', index: 1}]);
                    }
                });
        });
    },

    logout({commit, getters}) {
        return new Promise((resolve) => {
            if (getters.getAuthToken) {
                Axios.delete(`authentication/${ getters.getAuthToken }`).then(() => {
                    commit('logout');
                    resolve();
                }).catch(() => {
                    commit('logout');
                    resolve();
                });
            } else {
                // No Auth token, log out anyway
                commit('logout');
                resolve();
            }
        });
    },

    changePassword(store, data) {
        return new Promise((resolve, reject) => {
            Axios.post('change-password',
                {
                    data,
                },
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error.response.data);
            });
        });
    },

    forgotPassword(store, data) {
        return new Promise((resolve, reject) => {
            Axios.post('forgot-password',
                {
                    data,
                },
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error.response.data);
            });
        });
    },

    getCustomer({ commit, getters }) {
        Axios.get(`customer/${ getters.getCustomerId }`, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${ getters.getAuthToken }`,
                'Supplementary-Key': localStorage.getItem('supplementaryKey')
            }
        }).then(({ data }) => {
            commit('setCustomerAttributes', data.data.attributes);
        });
    },

    patchCustomer({ getters }, attributes) {
        let data = {
            id: getters.getCustomerId,
            type: 'customer',
            attributes: attributes
        };
        return new Promise((resolve, reject) => {
            Axios.patch(`customer/${ getters.getCustomerId }`,
                {
                    data,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${ getters.getAuthToken }`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error.response);
            });
        });
    },

    refresh({getters}) {
        return new Promise((resolve, reject) => {
            Axios.get('refresh', {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${getters.getAuthToken}`
                }
            }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },

    getPolicy({ commit, getters }, policyNumber) {
        Axios.get(`policy/${ policyNumber }`,
            {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${ getters.getAuthToken }`,
                    'Supplementary-Key': localStorage.getItem('supplementaryKey')
                }
            }
        ).then(response => {
            commit('addPolicy', response);

            // Track single policy detail viewed event by type (pet, caravan)
            trackEvent({
                event: 'policySingleDetailViewed',
                eventCategory: 'Policy',
                eventAction: 'View Detail',
                eventLabel: policyNumber
            });
        }).catch(response => {
            console.log(response);
        });
    },

    getAllPolicys({commit, getters}, policyArray) {
        policyArray.forEach((policyId) => {
            Axios.get(`policy/${policyId}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${getters.getAuthToken}`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            )
                .then(response => {
                    commit('addPolicy', response);

                    // Track policy detail viewed event by type (pet, caravan)
                    trackEvent({
                        event: 'policyDetailViewed',
                        eventCategory: 'Policy',
                        eventAction: 'View Detail',
                        eventLabel: policyId
                    });
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    getPolicyUpgradeSelection({commit, getters}, policyNumber) {
        return new Promise((resolve, reject) => {
            Axios.get(`policy/${policyNumber}/policy-upgrade-selection`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${getters.getAuthToken}`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            )
                .then((response) => {
                    commit('setUpgradeSelection', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getPolicyUpgradeOptions({commit, getters}, params) {
        if (!params[1]) {
            params[1] = 'none';
        }
        return new Promise((resolve, reject) => {
            Axios.get(`policy/${params[0]}/policy-upgrade-options?filter[policy-upgrade-selection]=${params[1]}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${getters.getAuthToken}`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            )
                .then((response) => {
                    commit('setUpgradeOptions', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getPolicyUpgrade({commit, getters}, upgradeOptionId) {
        return new Promise((resolve, reject) => {
            Axios.get(`policy-upgrade/${upgradeOptionId}`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${getters.getAuthToken}`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            )
                .then(response => {
                    commit('setUpgrade', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    if (error && error.response) {
                        reject(error.response.data);
                    } else {
                        reject();
                    }
                });
        });
    },

    patchPolicyUpgrade({getters}, upgradeOptionId) {
        let data = getters.getUpgrade.data;
        data.attributes['confirm-declarations'] = true;
        data.attributes['confirm-documents'] = true;
        data.attributes['confirm-payment'] = true;
        data.attributes['confirm-needs'] = true;
        return new Promise((resolve, reject) => {
            Axios.patch(`policy-upgrade/${upgradeOptionId}`,
                {data: data},
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${getters.getAuthToken}`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                })
                .then(response => {
                    if (!response) {
                        reject();
                    } else {
                        resolve(response.data);
                    }
                })
                .catch(response => {
                    reject(response);
                });
        });
    },

    setPrevPage({ commit }, from) {
        return commit('setPrevPage', from);
    },

    changeEmail({ getters }, data) {
        return new Promise((response, rejection) => {
            Axios.patch(`customer/${ getters.getCustomerId }`,
                {
                    data,
                },
            ).then(() => {
                // Track email change event
                trackEvent({
                    event: 'emailChanged',
                    eventCategory: 'User',
                    eventAction: 'Change Email',
                    eventLabel: 'Email Change'
                });

                response(response);

            }).catch((error) => {
                rejection(error.response.data);
            });
        });
    },

    addressLookup({ getters }, data) {
        return new Promise((resolve, reject) => {
            Axios.get(`address?postcode=${ data.postcode }&street=${ data.street }`,
                {
                    data,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${ getters.getAuthToken }`,
                        'Supplementary-Key': localStorage.getItem('supplementaryKey')
                    }
                }
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    register(data) {
        return new Promise((resolve, reject) => {
            Axios.post('register', {
                data: data,
            })
                .then(() => {
                    // Track new registration event
                    trackEvent({
                        event: 'newRegistration',
                        eventCategory: 'User',
                        eventAction: 'Register',
                        eventLabel: 'New Registration'
                    });

                    // Resolve after trackEvent is done
                    resolve();
                })
                .catch((error) => {
                    reject(error);  // Reject with error if Axios request fails
                });
        });
    },

    contactUs({ getters }, data) {
        return new Promise((resolve, reject) => {
            Axios.post('contact-form', {
                data,
            }, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${getters.getAuthToken}`,
                    'Supplementary-Key': localStorage.getItem('supplementaryKey')
                }
            })
                .then(() => {
                    // Track contact form submission event
                    trackEvent({
                        event: 'contactFormSubmitted',
                        eventCategory: 'Contact',
                        eventAction: 'Submit',
                        eventLabel: 'Contact Form Submission'
                    });

                    // Resolve after trackEvent is done
                    resolve();
                })
                .catch((error) => {
                    reject(error);  // Reject with error if Axios request fails
                });
        });
    }
};