import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Rollbar from 'rollbar';

const rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: false,
    payload: {
        environment: process.env.VUE_APP_ENV,
        client: {
            javascript: {
                code_version: '1.0',
            }
        }
    }
});

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faCoffee);

const app = createApp(App);

app.config.errorHandler = (err) => {
    rollbar.error(err);
    throw err; // rethrow
};

app.config.globalProperties.$rollbar = rollbar;

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.config.productionTip = false;

app.use(router).use(store).mount('#app');