<template>
    <div class="content-row">
        <div class="content-left">
            <strong><slot /></strong><br>
            <div v-if="edit === 'address'">
                <text-input v-model="address_lookup.line1" placeholder="House Nr" />
                <text-input v-model="address_lookup.postcode" placeholder="Postcode" />
                <div v-if="lookup_result">
                    <div class="lookup-details">
                        <p v-for="item in lookup_result" :key="item.id" @click="pickAddress(item)">
                            {{ item.attributes.description }}
                        </p>
                    </div>
                </div>
                <tie-alert v-if="lookup_error && lookup_error.length > 0">
                    {{ lookup_error }}
                </tie-alert>
                <a class="phone-number" @click="editAddress('address-manual')">Enter Manually</a>
            </div>
            <div v-else-if="edit === 'address-manual'">
                <text-input v-model="address.line1" placeholder="Street and house number" />
                <text-input v-model="address.line2" placeholder="2nd line of address" />
                <text-input v-model="address.line3" placeholder="Town/City" />
                <text-input v-model="address.line4" placeholder="County" />
                <text-input v-model="address.postcode" placeholder="Postcode" />
                <tie-alert v-for="error in address_errors" :key="error.index">
                    {{ error }}
                </tie-alert>
            </div>
            <div v-else>
                {{ modelValue['line1'] ? modelValue['line1'] : '' }}<br>
                {{ modelValue['line2'] ? modelValue['line2'] : '' }}<br>
                {{ modelValue['line3'] ? modelValue['line3'] : '' }}<br>
                {{ modelValue['line4'] ? modelValue['line4'] : '' }}<br>
                {{ modelValue['postcode'] ? modelValue['postcode'] : '' }}
            </div>
        </div>
        <div class="content-right">
            <div v-if="edit === 'address' || edit === 'address-manual'" class="address-btn-container">
                <tie-button variation="btn-outline-primary" @click="cancelEdit">
                    Cancel
                </tie-button>
                <tie-button v-if="edit === 'address'" variation="btn-primary" @click="addressLookup">
                    Lookup
                </tie-button>
                <tie-button v-if="edit === 'address-manual'" variation="btn-primary" @click="saveAddress">
                    Save
                </tie-button>
            </div>
            <div v-else class="address-btn-container center">
                <tie-button variation="btn-outline-primary" @click="editAddress('address')">
                    Edit
                </tie-button>
            </div>
        </div>
    </div>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';
import TieButton from '../tie-button/TieButton';
import TextInput from '../text-input/TextInput';

export default {
    name: 'AddressLookup',

    components: {
        TieAlert,
        TieButton,
        TextInput
    },

    props: {
        'modelValue': {
            type: Object,
            required: true,
            default() {
                return {
                    postcode: '',
                    line1: '',
                    line2: '',
                    line3: '',
                    line4: ''
                };
            },
        },
        currentEdit: {
            type: String,
            required: false,
            default: null
        }
    },

    emits: ['edit', 'save'],

    data() {
        return {
            edit: null,
            lookup_result: null,
            lookup_error: null,
            address_lookup: {
                line1: '',
                postcode: '',
            },
            address: {
                postcode: '',
                line1: '',
                line2: '',
                line3: '',
                line4: ''
            },
            address_errors: []
        };
    },

    watch: {
        currentEdit(value) {
            this.edit = value;
        }
    },

    methods: {
        editAddress(type) {
            this.$emit('edit', type);
            this.edit = type;
        },
        cancelEdit() {
            this.$emit('edit', null);
            this.edit = null;
            this.lookup_result = null;
            this.lookup_error = null;
            this.address_errors = [];
        },
        saveAddress() {
            if(this.validateManualAddress()) {
                this.$emit('save', this.address);
                this.edit = null;
                this.lookup_result = null;
                this.lookup_error = null;
                this.address_errors = [];
            }
        },
        validateManualAddress() {
            this.address_errors = [];
            let emptyFields = 0;
            for(let type in this.address) {
                if (type !== 'postcode' && this.address[type].length < 1) {
                    emptyFields++;
                }
            }
            if (emptyFields > 2) {
                this.address_errors.push('We need at least 2 lines of address and postcode to save your data.');
            }
            if (!this.validPostcode(this.address.postcode)) {
                this.address_errors.push('Entered postcode is invalid.');
            }
            return this.address_errors.length < 1;
        },
        validPostcode(postcode) {
            postcode = postcode.replace(/\s/g, '');
            let regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
            return regex.test(postcode);
        },
        addressLookup() {
            this.$store.dispatch('addressLookup',
                {
                    postcode: this.address_lookup.postcode,
                    street: this.address_lookup.line1
                }).then((response) => {
                this.lookup_error = null;
                this.lookup_result = response.data;
            }).catch((response) => {
                if (response.data.errors && response.data.errors[0].title) {
                    this.lookup_error = response.data.errors[0].title;
                }
            });
        },
        pickAddress(item) {
            this.edit = 'address-manual';
            this.lookup_result = null;
            this.address = {
                line1: item.attributes.address.line1,
                line2: item.attributes.address.line2,
                line3: item.attributes.address.line3,
                line4: item.attributes.address.line4,
                postcode: item.attributes.postcode,
            };
        },
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
@import "src/assets/styles/container";
@import "src/assets/styles/forms";

.address-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;

    &.center {
        align-items: center;
    }

    button {
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

.lookup-details {
    height: 140px;
    overflow: auto;
    background: $background-color;
    transition: opacity 0.4s, border 0.3s;
    border: 1px solid $form-border-color;
    border-bottom-color: white;
    border-top-color: white;
    border-radius: 3px;

    p {
        cursor: pointer;
        padding: 2px 3px;
        transition: background 0.2s, color 0.2s;

        &:hover {
            color: white;
            background: $primary;
        }
    }
}
</style>