<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <div class="back-button">
                    Back
                </div>
                <section>
                    <div class="error hide">
                        There has been an issue retrieving your policy at this time. Please try again later.
                    </div>
                    <div>
                        <h1>
                            My Policies
                        </h1>
                        <div class="content-block">
                            <policy-list />
                        </div>
                        <p>
                            If you would like to receive a physical copy of your policy documents through the post,
                            please contact our friendly team on <a href="tel:03300244007">03300 244 007</a> who will
                            be happy to help you. To update any of your information including communication
                            preferences please visit
                            <router-link to="/dashboard/customer-details">
                                My Profile.
                            </router-link>
                        </p>
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>

<script>
import PolicyList from '../../../components/policies/PolicyList';

export default {
    name: 'MyPolicies',
    components: {
        PolicyList
    },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";
@import "../../../assets/styles/container";

.hide {
    display: none;
}

.back-button {
    display: none;
}

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

a {
    line-height: inherit;
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: $primary-color-link-hover-dark;
    }
}

@media (max-width: 1200px) {
    .container-wrapper {
        font-size: 80%;
    }
}
</style>