<template>
    <footer>
        <a href="https://www.theinsuranceemporium.co.uk/">HOME</a> &bull;
        <a
            href="https://www.theinsuranceemporium.co.uk/accessibility"
            target="_blank"
        >ACCESSIBILITY</a> &bull;
        <a
            href="https://www.theinsuranceemporium.co.uk/privacy-policy"
            target="_blank"
        >PRIVACY POLICY</a> &bull;
        <a
            href="https://www.theinsuranceemporium.co.uk/about-us/what-is-the-insurance-emporium#our-commitment"
            target="_blank"
        >OUR COMMITMENT</a> &bull;
        <a
            href="https://www.theinsuranceemporium.co.uk/cookie-policy"
            target="_blank "
        >COOKIES</a>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter'
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

footer {
    font-family: $default-fonts;
    background: $footer-background-color;
    padding: 10px;
    color: $footer-text-color;
    text-align: center;

    a {
        color: #5F5F5F;
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;

        &:focus,
        &:hover {
            color: #0093AB;
        }
    }
}
</style>