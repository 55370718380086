<template>
    <dl>
        <dt>
            <a
                :href="api ? `${apiUrl}/api/v1${ document.link }` : document.link"
                target="_blank"
            >{{ document.name }}
                {{ document.created }}</a>
        </dt>
        <dd>
            <a
                :href="api ? `${apiUrl}/api/v1${ document.link }` : document.link"
                target="_blank"
                @click="trackDownload(document)"
            >
                <svg>
                    <use xlink:href="@/assets/images/sprite.svg#download" />
                </svg>
            </a>
        </dd>
    </dl>
</template>

<script>
import {trackEvent} from '../../../utilities/analytics';

export default {
    name: 'DocumentListItem',

    props: {
        document: {
            type: Object,
            required: true,
        },
        api: {
            type: Boolean,
            required: false,
            default: true
        }
    },
  
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL
        };
    },

    methods: {
        trackDownload(document) {
            // Tracking policy document downloads
            trackEvent({
                event: 'documentDownloaded',
                eventCategory: 'Document',
                eventAction: 'Download',
                eventLabel: document.name
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/container";

dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 21px 5px 18px;
    margin: 0;

    &:nth-of-type(odd) {
        background: $background-color;
    }

    dt {
        display: flex;
        align-items: center;
    }

    a {
        color: $primary-color;
        text-decoration: none;
        font-weight: 400;
    }
}

svg {
    height: 30px;
    width: 32px;
}

@media (min-width: 1200px) {
    svg {
        height: 40px;
        width: 42px;
    }
}
</style>