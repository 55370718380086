<template>
    <div class="home-container">
        <h1>REGISTER FOR MY EMPORIUM</h1>
        <p>To register your account, please enter the email address and date of birth you used to purchase your policy.</p>

        <tie-alert v-if="errorLink">
            We are sorry but there was an issue with this link. Please enter your email and date of birth below and we will send you a new link.
        </tie-alert>

        <tie-alert v-if="error">
            Unexpected Failure
        </tie-alert>

        <div class="card access-form">
            <form @keyup.enter="submitRegister">
                <email-input
                    v-model="email"
                >
                    Email
                </email-input>

                <dob-input
                    v-model="dob"
                />
            </form>
        </div>

        <div class="btn-container">
            <tie-button
                :is-disabled="!canRegister"
                variation="btn-login"
                @click="submitRegister"
            >
                Register
            </tie-button>
            <p>
                Already have an account?
                <router-link to="/">
                    Log In
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import TieButton from '../../components/tie-button/TieButton';
import EmailInput from '../../components/email-input/EmailInput';
import DobInput from '../../components/dob-input/DobInput';
import { mapGetters } from 'vuex';
import TieAlert from '../../components/tie-alert/TieAlert';
import Axios from 'axios';

export default {
    name: 'Register',

    components: {
        TieAlert,
        TieButton,
        EmailInput,
        DobInput,
    },

    data() {
        return {
            email: '',
            validEmail: false,
            validDob: false,
            dob: {
                day: null,
                month: null,
                year: null
            },
            requestActive: false,
            error: false
        };
    },

    computed: {
        ...mapGetters([
            'isLoggedIn'
        ]),

        canRegister() {
            return (this.validEmail && this.validDob);
        },

        errorLink() {
            return this.$route.query.message === 'invalid-link';
        }
    },

    watch: {
        isLoggedIn(value) {
            if (value) {
                this.$router.push('/dashboard');
            }
        },
        email() {
            this.validateEmail();
        },
        dob() {
            this.validateDob();
        }
    },

    methods: {
        validateEmail() {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.email)) {
                this.validEmail = true;
            } else {
                this.validEmail = false;
            }
        },

        validateDob() {
            if (this.dob.day && this.dob.month && this.dob.year) {
                this.validDob = true;
            } else {
                this.validDob = false;
            }
        },

        submitRegister() {
            if (this.requestActive || !this.canRegister) {
                return;
            }
            this.error = false;
            this.requestActive = true;

            let data = {
                attributes: {
                    email: this.email,
                    dob: `${this.dob.year}-${this.dob.month}-${this.dob.day}`,
                },
                id: '1',
                type: 'register'
            };

            Axios.post('register',
                {
                    data
                },
                {
                    withCredentials: true,
                }
            ).then(() => {
                this.$rollbar.info('Register complete.');
                this.$router.push('/register-complete');
                this.requestActive = false;
            }).catch((error) => {
                //TODO: Could we add an error message if this fails?
                console.log(error);
                this.error = true;
                this.requestActive = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/container";
</style>