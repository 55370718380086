<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <h1>Contact Us</h1>
                <p class="subtitle">
                    There are plenty of ways to get in touch with us. Here are our details:
                </p>

                <tie-alert v-if="success" variant="success">
                    Thanks for getting in touch! Every customer is important to us and we're always keen to help. Sit tight, we'll be in touch as soon as possible!
                </tie-alert>

                <tie-alert v-if="failure">
                    There has been a problem sending your contact messsage, please try again.
                </tie-alert>

                <h2>Online Contact Form</h2>
                <div class="content-block">
                    <select-input v-model="department" :options="options" placeholder="Department" />

                    <select-input v-model="policyNo" :options="policiesInfo" placeholder="Policy Number" />

                    <text-input v-model="subject" placeholder="Subject" />

                    <text-area v-model="message" placeholder="Message" />

                    <div class="btn-container">
                        <tie-button
                            :is-disabled="!canSubmit"
                            variation="btn-primary"
                            @click="submitContact"
                        >
                            Submit
                        </tie-button>
                    </div>
                </div>

                <h2>Phone</h2>
                <div class="content-block list-of-two">
                    <p data-title="Customer Queries">
                        <a href="tel:03300244007">03300 244 007</a>
                    </p>
                    <p data-title="Claims Department">
                        <a href="tel:03300244006">03300 244 006</a>
                    </p>
                    <p data-title="Customer Experience">
                        <a href="tel:03300244007">03300 244 007</a>
                    </p>
                </div>

                <h2>Post</h2>
                <div class="content-block post-block">
                    <p><strong>The Insurance Emporium</strong></p>
                    <p>Thorpe Underwood Hall</p>
                    <p>Ouseburn</p>
                    <p>York</p>
                    <p>YO26 9SS</p>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import TieButton from '../../../components/tie-button/TieButton';
import TieAlert from '../../../components/tie-alert/TieAlert';
import SelectInput from '../../../components/select-input/SelectInput';
import TextInput from '../../../components/text-input/TextInput';
import TextArea from '../../../components/text-area/TextArea';
import { mapGetters } from 'vuex';

export default {
    name: 'ContactUs',

    components: {
        TieButton,
        TieAlert,
        TextInput,
        TextArea,
        SelectInput
    },

    data() {
        return {
            department: null,
            policyNo: null,
            subject: '',
            message: '',
            options: [{ key: 'Customer Queries', value: 'customer_queries' }, { key:'Claims', value: 'claims' }],
            success: false,
            failure: false,
            requestActive: false
        };
    },

    computed: {
        ...mapGetters([
            'getPolicies'
        ]),

        policiesInfo() {
            return this.getPolicies.map(policy => {
                return {
                    value: policy.id,
                    key: policy.attributes ? `${policy.attributes.product} - Policy No: ${policy.id}` : `Policy No: ${policy.id}`
                };
            }).sort((a, b) => {
                return a.value < b.value ? -1 : (a.value > b.value ? 1 : 0);
            });
        },

        canSubmit() {
            return !this.requestActive && this.department !== null && this.policyNo !== null && this.subject.length > 0 && this.message.length > 0;
        }
    },

    methods: {
        submitContact() {
            if (!this.canSubmit) {
                return;
            }
            this.requestActive = true;
            this.failure = false;
            this.success = false;

            let data = {
                attributes: {
                    department: this.department,
                    message: this.message,
                    policy: this.policyNo,
                    subject: this.subject
                },
                id: '1',
                type: 'contact-form'
            };

            this.$store.dispatch('contactUs', data).then(() => {
                this.success = true;
                this.department = null;
                this.policyNo = null;
                this.message = '';
                this.subject = '';
                this.requestActive = false;
            }).catch(() => {
                this.failure = true;
                this.requestActive = false;
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
@import "src/assets/styles/container";
@import "src/assets/styles/forms";

.btn-container {
    text-align: right;
}

.list-of-two {
    p {
        position: relative;
        padding-left: 50%;
        min-height: 1.3rem;
        line-height: 1.3;
        text-align: right;
        margin: 8px 0 0 0;

        &::before {
            content: attr(data-title);
            position: absolute;
            left: 0;
            font-weight: 500;
        }
    }
}

.post-block {
    p {
        margin: auto;
    }
}

.hide {
    display: none;
}

.back-button {
    display: none;
}

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

.subtitle {
    padding-left: 1.5rem;
}

a {
    line-height: inherit;
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: $primary-color-link-hover-dark;
    }
}

@media (max-width: 1200px) {
    .container-wrapper {
        font-size: 80%;
    }
}
</style>
