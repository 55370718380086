<template>
    <div class="parent-container">
        <div class="parent-container inner">
            <site-header :show-hamburger="isLoggedIn" />
            <div class="main-container">
                <div class="navigation-container" :class="{ 'middle': !isLoggedIn, 'sidebar-open': open }">
                    <hamburger-sidebar v-if="isLoggedIn" />
                    <div v-if="statusFail" class="maintenance-container">
                        <h1>We'll be back soon!</h1>
                        <div class="card">
                            <svg id="gear-small"><use xlink:href="@/assets/images/sprite.svg#gear-small" /></svg>
                            <svg id="gear"><use xlink:href="@/assets/images/sprite.svg#gear" /></svg>
                            <div class="error">
                                <p>We’re just carrying out some important updates to My Emporium. Thank you for waiting!</p>
                                <p>If you need us in the meantime please contact us on <a href="tel:03300244007">03300 244 007</a></p>
                            </div>
                        </div>
                    </div>
                    <router-view v-if="!statusFail" v-slot="{ Component }">
                        <component :is="Component" :key="$route.path" />
                    </router-view>
                </div>
            </div>
            <site-footer />
        </div>
        <footer-copyright />
        <loading-screen v-if="loading" />
    </div>
</template>

<script>
import SiteFooter from './components/footer/SiteFooter';
import SiteHeader from './components/header/SiteHeader';
import LoadingScreen from './components/loading-screen/LoadingScreen';
import FooterCopyright from './components/footer/FooterCopyright';
import HamburgerSidebar from './components/sidebar/HamburgerSidebar';
import {mapGetters} from 'vuex';

export default {
    components: {
        HamburgerSidebar,
        FooterCopyright,
        LoadingScreen,
        SiteFooter,
        SiteHeader
    },
    data() {
        return {
            loading: true,
            statusFail: false
        };
    },
    computed: {
        ...mapGetters([
            'isLoggedIn'
        ]),

        open() {
            return this.$store.state.isSidebarOpen;
        }
    },
    mounted() {
        this.$store.dispatch('api_status').catch(() => {
            this.statusFail = true;
        });
        setTimeout(() => this.loading = false, 2000);
    }
};
</script>

<style lang="scss">
@import "assets/styles/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "src/assets/styles/_fonts";

html {
    font-size: 80%;

    @media (min-width: 1200px) {
        font-size: 100%;
    }
}

body.modal-open {
    overflow: hidden;
}

.parent-container {
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;
    font-family: $default-fonts;
    background-color: $background-color;

    .main-container {
        background: $background-color;
        display: flex;
        flex: 1 1 auto;
        min-height: calc(100vh - 108px);
        font-family: $default-fonts;

        @media screen and (min-width: 75em) {
            min-height: calc(100vh - 146px);
        }
    }

    .navigation-container {
        display: flex;
        flex: 1 1 auto;
        min-height: calc(100vh - 240px);
        justify-content: center;
        font-size: 15px;

        &.sidebar-open {
            main {
                @media (max-width: 720px) {
                    display: none;
                }
            }
        }

        &.middle {
            align-items: center;
        }
    }

    &.inner {
        @media (min-width: 475px) {
            min-height: unset;
        }
    }
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.maintenance-container {
    margin: 1rem auto;
    max-width: 800px;
    min-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1 auto;
    text-align: center;

    h1 {
        color: #00ABC7;
        text-transform: uppercase;
    }

    .card {
        margin-top: 10px;
        margin-bottom: 1.2rem;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        background: #FEFEFE;
        padding: 1.5rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        animation: fade-in-0d91fc38 0.3s forwards cubic-bezier(0.47, 0, 0.75, 0.72);

        #gear {
            animation: gear 3s infinite ease-in-out;
            width: 60px;
        }

        #gear-small {
            animation: gear-small 3s infinite ease-in-out;
            width: 40px;
            position: relative;
            top: -37px;
            right: -17px;
        }

        @keyframes gear {
            0% { transform: rotate(0); }
            100% { transform: rotate(360deg); }
        }

        @keyframes gear-small {
            0% { transform: rotate(0); }
            100% { transform: rotate(-360deg); }
        }
    }
}
</style>