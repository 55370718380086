<template>
    <button
        class="btn"
        :class="[variation, configuration]"
        :disabled="isDisabled"
        @click="onTieButtonClick"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'TieButton',

    props: {
        variation: {
            type: String,
            default: 'btn-primary',
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    emits: ['tie-button-click'],

    computed: {
        configuration() {
            return {
                'is-disabled': this.isDisabled,
                'btn-sm': this.isSmall,
                'is-loading': this.isLoading
            };
        }
    },

    methods: {
        onTieButtonClick() {
            this.$emit('tie-button-click');
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/_buttons.scss";
@import "src/assets/styles/variables";

.btn-outline-primary {
    border: 1px solid $primary-color;
    font-weight: 700;
    color: $primary-color;
    padding: 0.7rem;
    font-size: 0.8rem;
    line-height: 1;
    text-transform: uppercase;

    &:hover {
        border-color: $primary-color;
        background-color: $primary-color;
        color: white;
    }
}

.btn-primary {
    line-height: 1;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: #FEFEFE;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.7rem;

    &:hover,
    &:active {
        border-color: $primary-color-link-hover-dark;
        background-color: $primary-color-link-hover-dark;
    }

    &[disabled] {
        background: $primary;
        border-color: $primary;
        opacity: 25%;
        cursor: not-allowed;
    }
}

.btn-login {
    background-color: #EC0044;
    color: #FEFEFE;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 35px;
    padding-right: 35px;
}

.button,
.button.disabled,
.button.disabled:focus,
.button.disabled:hover,
.button[disabled],
.button[disabled]:focus,
.button[disabled]:hover {
    background-color: #00ABC7;
    color: #FEFEFE;
}

.button {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    font-family: $default-fonts;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.8rem;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}
</style>