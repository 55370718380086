<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <div class="back-button">
                    Back
                </div>
                <section>
                    <div class="error hide">
                        There has been an issue retrieving your policy at this time. Please try again later.
                    </div>
                    <div>
                        <h1>
                            Claim Centre
                        </h1>

                        <div class="content-block">
                            <p><strong>Ready to start your claim?</strong></p>
                            <p>Your claim form is available to download below. Please fill it in and send it to us, we'll do the rest!</p>
                            <p>
                                Download your claim form for:
                                <a :href="`${apiUrl}/api/v1/` + document.link" target="_blank" @click="trackDownload(document)">{{ document.name }}</a>
                            </p>
                        </div>
                        <h2>
                            What Happens Next?
                        </h2>
                        <div class="content-block">
                            <p>
                                <strong>1.</strong> Your claim form should have downloaded. Please ensure that you've filled it in correctly and truthfully, completing all sections!
                            </p>
                            <p>
                                <strong>2.</strong> Attach any relevant documentation and send it to us, using the contact details at the bottom of the form.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>

<script>
import {trackEvent} from "../../utilities/analytics";

export default {
    name: 'DocumentDownload',

    props: {
        document: {
            type: Object,
            required: true,
            default() {
                return {
                    id: '1',
                    name: 'Test',
                    link: '#'
                };
            },
        }
    },

    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL
        };
    },

    methods: {
        trackDownload(document) {
            // Tracking claims form downloads
            trackEvent({
                event: 'claimDocumentDownloaded',
                eventCategory: 'ClaimDocument',
                eventAction: 'Download',
                eventLabel: document.name
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";
@import "../../assets/styles/container";

.hide {
    display: none;
}

.back-button {
    display: none;
}

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

.content-block {
    padding: 2rem 2.5rem;
    margin-top: 14px;
    text-align: left;
}

@media (min-width: 1200px) {
    .content-block {
        padding: 2rem 2.5rem;
        margin-top: 14px;
    }
}

select:focus {
    background-color: #FEFEFE;
    border: 1px solid #00ABC7;
    transition: border-color 0.25s ease-in-out;
}

a {
    text-decoration: none;
}
</style>