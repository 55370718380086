<template>
    <div>
        <policy-card
            v-for="policy in policies"
            :key="`key-${policy.id}`"
            :policy="policy"
        />
    </div>
</template>

<script>
import PolicyCard from './PolicyCard';
import {mapGetters} from 'vuex';

export default {
    name: 'PolicyList',

    components: {
        PolicyCard,
    },

    computed: {
        ...mapGetters([
            'getPolicyArray',
            'getPolicies'
        ]),

        policies() {
            let policies = this.getPolicies;
            // Sort policies (List of ID strings) - Ascending
            policies.sort((a, b) => a.id.localeCompare(b.id, undefined, { numeric: true }));
            return policies;
        }
    },

    mounted() {
        this.$store.dispatch('getAllPolicys', this.getPolicyArray);
    }
};
</script>
