import { createStore } from 'vuex';

import globalState from './globalState';
import globalGetters from './globalGetters';
import globalActions from './globalActions';
import globalMutations from './globalMutations';

export default createStore({
    plugins: [(store) => {
        store.subscribeAction({
            after: (action, state) => {
                if (state.isLoggedIn) {
                    store.commit('restartTimer');
                }
            }
        });
    }],
    state: globalState,
    getters: globalGetters,
    actions: globalActions,
    mutations: globalMutations,
});