<template>
    <header class="header">
        <div
            :class="{ center: !showHamburger }"
            class="header-wrapper"
        >
            <router-link :to="headerLogoRoute">
                <img
                    alt="TIE"
                    class="logo"
                    src="@/assets/images/logo-tie.svg"
                >
            </router-link>
            <hamburger-menu v-if="showHamburger" />
            <div v-if="showHamburger" class="logout">
                <router-link to="/logout">
                    <svg>
                        <use xlink:href="@/assets/images/sprite.svg#logout" />
                    </svg>
                    Log out
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
import HamburgerMenu from './HamburgerMenu';
import { mapGetters } from 'vuex';

export default {
    components: {
        HamburgerMenu
    },

    props: {
        showHamburger: {
            type: Boolean,
            required: true,
            default: false
        }
    },

    computed: {
        ...mapGetters([
            'isLoggedIn'
        ]),
        headerLogoRoute() {
            return this.isLoggedIn ? '/dashboard/my-policies' : '/tie';
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.header {
    background: $background-color;
    border-bottom: 5px solid $header-border-color;
    padding: 6px 0.71023rem;
    height: 73px;
    position: relative;
    z-index: 101;

    img {
        height: 52px;
        width: auto;
        max-width: 200px;
        padding: 5px 0 2px 12px;
    }

    .header-wrapper {
        margin: 0 auto;

        &.center {
            text-align: center;
        }
    }

    @media screen and (max-width: 1199px) {
        .logout {
            display: none;
        }
    }

    .logout {
        position: absolute;
        right: 0;
        top: 0;
        height: 100px;
        padding: 0 20px;
        font-weight: 500;
        font-size: 14px;

        a {
            line-height: inherit;
            color: $primary-color;
            text-decoration: none;
            cursor: pointer;
        }

        svg {
            width: 50px;
            height: 100px;
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
        }
    }
}

@media screen and (min-width: 75em) {
    .header {
        height: 107px;
        margin: 0;

        img {
            height: 65px;
            width: auto;
            max-width: none;
            padding: 0;
            margin: 10px 0 0 0.9vw;
        }
    }
}
</style>