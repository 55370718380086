<template>
    <div class="container-wrapper">
        <div class="container">
            <main v-if="customer">
                <h1>My Profile</h1>

                <h2>Personal Details</h2>
                <div class="content-block">
                    <!-- Customer Name -->
                    <div class="content-row">
                        <p class="content-left">
                            <b>Name</b><br>
                            {{ customer.name }}
                        </p>
                    </div>

                    <address-lookup
                        v-if="address"
                        v-model="address"
                        :current-edit="edit"
                        @edit="editDetail"
                        @save="saveAddress"
                    >
                        Correspondence Address
                    </address-lookup>

                    <!-- Mobile Number -->
                    <div class="content-row">
                        <div class="content-left">
                            <strong>Mobile Number</strong><br>
                            <div v-if="edit === 'mobile_number'">
                                <text-input v-model="mobile_number" placeholder="Enter your mobile number" :error="mobile_number_error" />
                            </div>
                            <div v-else>
                                {{ customer.mobile_number }}
                            </div>
                        </div>
                        <div class="content-right">
                            <div v-if="edit === 'mobile_number'" class="number-btn-container">
                                <tie-button variation="btn-outline-primary" @click="cancelEdit">
                                    Cancel
                                </tie-button>
                                <tie-button variation="btn-primary" @click="saveNumber('mobile_number')">
                                    Save
                                </tie-button>
                            </div>
                            <div v-else>
                                <tie-button variation="btn-outline-primary" @click="editDetail('mobile_number')">
                                    Edit
                                </tie-button>
                            </div>
                        </div>
                    </div>

                    <!-- Home Number -->
                    <div class="content-row">
                        <div class="content-left">
                            <strong>Home Number</strong><br>
                            <div v-if="edit === 'home_number'">
                                <text-input v-model="home_number" placeholder="Enter your home number" :error="home_number_error" />
                            </div>
                            <div v-else>
                                {{ customer.home_number }}
                            </div>
                        </div>
                        <div class="content-right">
                            <div v-if="edit === 'home_number'" class="number-btn-container">
                                <tie-button variation="btn-outline-primary" @click="cancelEdit">
                                    Cancel
                                </tie-button>
                                <tie-button variation="btn-primary" @click="saveNumber('home_number')">
                                    Save
                                </tie-button>
                            </div>
                            <div v-else>
                                <tie-button variation="btn-outline-primary" @click="editDetail('home_number')">
                                    Edit
                                </tie-button>
                            </div>
                        </div>
                    </div>

                    <!-- Evening Number -->
                    <div class="content-row">
                        <div class="content-left">
                            <strong>Evening Number</strong><br>
                            <div v-if="edit === 'evening_number'">
                                <text-input v-model="evening_number" placeholder="Enter your evening number" :error="evening_number_error" />
                            </div>
                            <div v-else>
                                {{ customer.evening_number }}
                            </div>
                        </div>
                        <div class="content-right">
                            <div v-if="edit === 'evening_number'" class="number-btn-container">
                                <tie-button variation="btn-outline-primary" @click="cancelEdit">
                                    Cancel
                                </tie-button>
                                <tie-button variation="btn-primary" @click="saveNumber('evening_number')">
                                    Save
                                </tie-button>
                            </div>
                            <div v-else>
                                <tie-button variation="btn-outline-primary" @click="editDetail('evening_number')">
                                    Edit
                                </tie-button>
                            </div>
                        </div>
                    </div>
                </div>

                <h2>Communication Preferences</h2>
                <div class="content-block">
                    <p>I would like my insurance policy documentation:</p>
                    <input
                        id="radio1"
                        type="radio"
                        name="radio"
                        checked
                    >
                    <label class="is-radio" for="radio1">to be delivered to My Emporium account</label>
                    <p>If you would like a physical copy of your policy documents sending through the post, please contact our team on <a href="tel:03300244007">03300 244 007</a> after purchasing your policy.</p>
                </div>

                <h2>Marketing Preferences</h2>
                <div class="content-block post-block">
                    <p>We would like to contact you from time to time at the email address, mobile number, telephone number or postal address you have provided; every email we send includes an unsubscribe link so you can easily change your mind. To learn more about how your personal data is handled please read our <a href="https://www.theinsuranceemporium.co.uk/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                    <p>Please select how you would like us to get in contact with information about our products and/or offers which we feel may be of interest to you:</p>
                    <div class="btn-container">
                        <div class="marketing__buttons" :class="customer['marketing_preferences'].email ? ' true' : ''" @click="toggleMarketingPreference('email')">
                            <svg>
                                <use xlink:href="@/assets/images/sprite.svg#email" />
                            </svg>
                            <small>Email</small>
                        </div>
                        <div class="marketing__buttons" :class="customer['marketing_preferences'].post ? ' true' : ''" @click="toggleMarketingPreference('post')">
                            <svg>
                                <use xlink:href="@/assets/images/sprite.svg#post" />
                            </svg>
                            <small>Post</small>
                        </div>
                        <div class="marketing__buttons" :class="customer['marketing_preferences'].sms ? ' true' : ''" @click="toggleMarketingPreference('sms')">
                            <svg>
                                <use xlink:href="@/assets/images/sprite.svg#sms" />
                            </svg>
                            <small>SMS</small>
                        </div>
                        <div class="marketing__buttons" :class="customer['marketing_preferences'].tel ? ' true' : ''" @click="toggleMarketingPreference('tel')">
                            <svg>
                                <use xlink:href="@/assets/images/sprite.svg#phone" />
                            </svg>
                            <small>Phone</small>
                        </div>
                    </div>
                </div>

                <p>If you no longer want to remain registered for the My Emporium portal then just get in contact with our friendly team on <a href="tel:03300244007">03300 244 007</a> or email us at <a href="mailto:hello@emporium.co.uk">hello@emporium.co.uk</a> and we can unregister your account!</p>
            </main>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TieButton from '../../../components/tie-button/TieButton';
import TextInput from '../../../components/text-input/TextInput';
import AddressLookup from '../../../components/address-lookup/AddressLookup';

export default {
    name: 'CustomerDetails',

    components: {
        TieButton,
        TextInput,
        AddressLookup
    },

    data() {
        return {
            edit: null,
            address: {
                postcode: '',
                line1: '',
                line2: '',
                line3: '',
                line4: ''
            },
            mobile_number: '',
            home_number: '',
            evening_number: '',
            mobile_number_error: '',
            home_number_error: '',
            evening_number_error: ''
        };
    },

    computed: {
        ...mapGetters([
            'getCustomer'
        ]),
        customer() {
            if (!this.getCustomer) {
                return null;
            }

            let customerAttributes = this.getCustomer.attributes;
            let name = customerAttributes.name.title + ' ' + customerAttributes.name.forename + ' ' + customerAttributes.name.surname;
            let newCustomer = {
                name: name,
                mobile_number: customerAttributes.telephone.mobile,
                home_number: customerAttributes.telephone.day,
                evening_number: customerAttributes.telephone.evening,
                address: customerAttributes.address,
                marketing_preferences: customerAttributes['marketing-preferences']
            };
            this.populateDataFromCustomer(customerAttributes);

            return newCustomer;
        }
    },

    mounted() {
        this.$store.dispatch('getCustomer');
    },

    methods: {
        editDetail(type) {
            this.edit = type;
        },
        cancelEdit() {
            this.edit = null;
            this.home_number_error = '';
            this.evening_number_error = '';
            this.mobile_number_error = '';
        },
        saveNumber(type) {
            if (this.validateNumber(type)) {
                let customerAttributes = {
                    'telephone': {
                        day: this.home_number,
                        evening: this.evening_number,
                        mobile: this.mobile_number
                    }
                };
                this.$store.dispatch('patchCustomer', customerAttributes).then(() => {
                    this.$store.dispatch('getCustomer');
                    this.edit = null;
                });
            }
        },
        validateNumber(type) {
            this[type+'_error'] = '';
            let isANumber = isNaN(this[type]) === false;
            let validLength = false;
            switch(type) {
                case 'mobile_number':
                    validLength = this[type].length === 11;
                    if (isANumber && validLength) {
                        return true;
                    } else {
                        this.mobile_number_error = 'Your Mobile Number must be 11 digits long and can only contain numbers.';
                        return false;
                    }
                default:
                    validLength = this[type].length === 10 || this[type].length === 11;
                    if (isANumber && validLength) {
                        return true;
                    } else {
                        this[type+'_error'] = 'Your ' + type.replace('_', ' ') + ' must be 10-11 digits long and can only contain numbers.';
                        return false;
                    }
            }
        },
        saveAddress(address) {
            let customerAttributes = {
                'address': address
            };
            this.$store.dispatch('patchCustomer', customerAttributes).then(() => {
                this.$store.dispatch('getCustomer');
                this.edit = null;
            });
        },
        toggleMarketingPreference(type) {
            let updatedPreferences = this.customer.marketing_preferences;
            updatedPreferences[type] = !updatedPreferences[type];
            let customerAttributes = {
                'marketing-preferences': updatedPreferences
            };
            this.$store.dispatch('patchCustomer', customerAttributes);
        },
        populateDataFromCustomer(customerAttributes) {
            this.mobile_number = customerAttributes.telephone.mobile;
            this.home_number = customerAttributes.telephone.day;
            this.evening_number = customerAttributes.telephone.evening;
            this.address = customerAttributes.address;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
@import "src/assets/styles/container";
@import "src/assets/styles/forms";
@import "src/assets/styles/marketing-buttons";

.number-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    button {
        margin-left: 10px;

        &:nth-child(1) {
            margin-bottom: 10px;
        }
    }
}
</style>
