<template>
    <div v-if="customerAttributes" class="container-wrapper">
        <div class="container">
            <main>
                <section v-if="!view">
                    <h1>SECURITY DETAILS</h1>

                    <tie-alert v-if="success" variant="success">
                        {{ success }}
                    </tie-alert>

                    <div class="content-block">
                        <dl>
                            <dt>
                                Your email address for your account is:
                            </dt>
                            <dd>
                                {{ customerAttributes['email'] }}
                            </dd>
                            <dt v-if="customerAttributes['pending-email']">
                                Once confirmed, your new email address will be:
                            </dt>
                            <dd v-if="customerAttributes['pending-email']">
                                {{ customerAttributes['pending-email'] }}<span class="pending-text">Pending</span>
                            </dd>
                        </dl>

                        <p>
                            <a v-if="customerAttributes['pending-email']" class="resend" @click="resendEmail">Resend Email Confirmation</a>
                        </p>

                        <tie-alert v-if="error">
                            {{ error }}
                        </tie-alert>

                        <p>
                            Here you can change the email or password you use when you log in to My Emporium
                        </p>
                        <div class="btn-container">
                            <tie-button
                                variation="btn-primary"
                                @click="changeEmail"
                            >
                                Change Email
                            </tie-button>
                            <tie-button
                                variation="btn-primary"
                                @click="changePassword"
                            >
                                Change Password
                            </tie-button>
                        </div>
                    </div>
                </section>
                <change-email v-if="view === 'email'" />
                <change-password v-if="view === 'password'" />
            </main>
        </div>
    </div>
</template>

<script>
import ChangeEmail from '../../../components/security-details/ChangeEmail';
import ChangePassword from '../../../components/security-details/ChangePassword';
import TieButton from '../../../components/tie-button/TieButton';
import {mapGetters} from 'vuex';
import TieAlert from '../../../components/tie-alert/TieAlert';

export default {
    name: 'SecurityDetails',

    components: {
        TieAlert,
        TieButton,
        ChangeEmail,
        ChangePassword
    },

    computed: {
        ...mapGetters([
            'getCustomer',
        ]),

        customerAttributes() {
            if(!this.getCustomer) {
                return;
            }
            return this.getCustomer.attributes;
        },

        view() {
            return this.$route.query.view;
        },

        success() {
            return this.$route.query.success;
        },

        error() {
            return this.$route.query.error;
        }
    },

    mounted() {
        this.$store.dispatch('getCustomer');
    },

    methods: {
        changeEmail() {
            this.$router.push({
                name: 'SecurityDetails',
                query: {
                    view: 'email'
                },
            });
        },
        changePassword() {
            this.$router.push({
                name: 'SecurityDetails',
                query: {
                    view: 'password'
                },
            });
        },
        resendEmail() {
            this.errorMessage = '';
            let customerAttributes = {
                'pending-email': this.getCustomer.attributes['pending-email']
            };
            this.$store.dispatch('patchCustomer', customerAttributes).then(() => {
                this.$store.dispatch('getCustomer');
                this.$router.push({
                    name: 'SecurityDetails',
                    query: {
                        success: 'Check your inbox! We\'ve resent your confirmation link to your new email address!'
                    },
                });
            }).catch(() => {
                this.$router.push({
                    name: 'SecurityDetails',
                    query: {
                        error: 'There has been a problem and we cannot resend your email confirmation at this time! We apologise for any inconvenience'
                    },
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/container';
@import 'src/assets/styles/headings';
@import 'src/assets/styles/forms';

.btn-container {
    text-align: center;

    button {
        margin-left: 2px;
        margin-right: 2px;
    }
}

.content-block {
    @extend .content-block-padded;
}

dt {
    font-weight: 400;
    line-height: 1.4;
}

dd {
    font-weight: 500;
    margin-bottom: 1rem;
}

.pending-text {
    color: $success;
    margin-left: 36px;
    font-weight: 400;
}

</style>
