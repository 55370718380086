<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <div class="back-button">
                    Back
                </div>
                <section v-if="!showDocumentDownload">
                    <div v-if="!getPolicies" class="error">
                        There has been an issue retrieving your policy at this time. Please try again later.
                    </div>
                    <div>
                        <h1>
                            Claim Centre
                        </h1>
                        <div class="content-block make-claim-content">
                            <h2>How To Make a Claim With The Insurance Emporium</h2>
                            <p class="make-claim">
                                <ul>
                                    <li>Do you insure your cat or dog with us?</li>
                                    <li>Are you making a <strong>New</strong> or <strong>Continuation</strong> Vet's Fees claim?</li>
                                    <li>Why not try our digital claims journey?</li>
                                </ul>
                                <a href="https://tie.capture.claimtechnology.co.uk/chat" target="_blank">Click here to get started</a>
                            </p>
                            <p>(More digital journeys for more sections and product types will follow soon)</p>
                        </div>
                        <h2>
                            Request a Claim Form
                        </h2>
                        <div class="content-block">
                            <drop-down v-model="selectedPolicy" :options="policyOptions">
                                Policy No
                            </drop-down>

                            <drop-down v-model="selectedDocument" :options="documentOptions">
                                Claim form
                            </drop-down>

                            <tie-button :is-disabled="!canSubmit" @click="submit">
                                Send Request
                            </tie-Button>
                        </div>
                    </div>
                </section>
                <document-download v-if="showDocumentDownload" :document="selectedDocumentData" />
            </main>
        </div>
    </div>
</template>

<script>
import TieButton from '../../../components/tie-button/TieButton';
import DropDown from '../../../components/claim-centre/DropDown';
import DocumentDownload from '../../../components/claim-centre/DocumentDownload';
import {mapGetters} from 'vuex';

export default {
    name: 'ClaimCentre',

    components: {
        DropDown,
        TieButton,
        DocumentDownload
    },

    data() {
        return {
            selectedPolicy: '',
            selectedDocument: ''
        };
    },

    computed: {
        ...mapGetters([
            'getPolicies',
        ]),

        policyOptions() {
            return this.getPolicies.map(policy => {
                return {
                    id: policy.id,
                    text: policy.attributes ? `${policy.attributes.product} - Policy No: ${policy.id}` : `Policy No: ${policy.id}`
                };
            }).sort((a, b) => {
                return a.id < b.id ? -1 : (a.id > b.id ? 1 : 0);
            });
        },

        documentOptions() {
            if (!this.selectedPolicyData) {
                return [];
            }
            return this.selectedPolicyClaimForms.map((form) => {
                return {
                    id: form.id,
                    text: `${this.selectedPolicyData.attributes.product} - ${form.name}`
                };
            });
        },

        selectedPolicyData() {
            return this.getPolicies.find(policy => {
                return this.selectedPolicy === policy.id;
            });
        },

        selectedDocumentData() {
            return this.selectedPolicyClaimForms.find(policy => {
                return this.selectedDocument === policy.id;
            });
        },

        selectedPolicyClaimForms() {
            if(!this.selectedPolicyData) {
                return [];
            }

            return this.selectedPolicyData.attributes['claim-forms'];
        },

        canSubmit() {
            return this.selectedPolicy.length > 0 && this.selectedDocument.length > 0;
        },

        showDocumentDownload() {
            return this.$route.query.download;
        }
    },

    methods: {
        submit() {
            this.$router.push({
                name: 'ClaimCentre',
                query: {
                    download: 'true'
                },
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables";
@import "../../../assets/styles/container";

.hide {
    display: none;
}

.back-button {
    display: none;
}

section {
    animation-name: fade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
}

.content-block {
    padding: 2rem 2.5rem;
    margin-top: 14px;
    text-align: center;
}

@media (min-width: 1200px) {
    .content-block {
        padding: 2rem 2.5rem;
        margin-top: 14px;
    }
}

select:focus {
    background-color: #FEFEFE;
    border: 1px solid #00ABC7;
    transition: border-color 0.25s ease-in-out;
}

.error {
    text-align: center;
    padding: 5px 70px;
    color: #EC0044;
    background-color: #FFFFFF;
    border: 1px solid #EC0044;
    border-radius: 3px;
    font-size: 0.875em;
    margin: 1rem 0;
}

.make-claim-content {
    text-align: left;
}

.make-claim-content h2 {
    padding-left: 0;
}

.make-claim-content a {
    text-decoration: none;
}
</style>