<template>
    <label :for="identifier"><slot /></label>
    <input
        :id="identifier"
        :value="modelValue"
        name="email"
        autocomplete="off"
        :placeholder="placeholder"
        class="email"
        :class="{ error: error && error.length > 0 }"
        type="email"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="validateEmail"
    >
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';

export default {
    name: 'EmailInput',

    components: {
        TieAlert
    },

    props: {
        identifier: {
            type: String,
            required: false,
            default: 'email',
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Your Email',
        },

        'modelValue': {
            type: String,
            required: false,
            default: '',
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            error: '',
        };
    },

    methods: {
        validateEmail() {
            if (/^.*@[^.]*\..*$/.test(this.modelValue)) {
                this.error = '';
                return true;
            } else {
                this.error = 'Please enter a valid email address';
                return false;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/forms";

label {
    font-weight: bold;
    margin: 14px 0 3px;
    position: relative;
}

</style>
