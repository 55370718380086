<template>
    <div
        v-if="policy && policy.attributes"
        class="policy-card-wrapper"
    >
        <h2>
            <span v-if="policy.attributes">{{ policy.attributes.product }} Insurance</span>
        </h2>

        <div
            v-if="policy.attributes"
            class="card-section"
        >
            <div class="policy-information">
                <div>
                    Policy No: <span>{{ policy.id }}</span>
                </div>
                {{ policy.attributes.insured }}
            </div>
            <router-link :to="`/dashboard/my-policies/${ policy.id }`">
                <tie-Button class="button right">
                    View Policy
                </tie-Button>
            </router-link>
        </div>

        <div v-if="policy < 1">
            We could not retrieve your policy at this time. please try again later.
        </div>
    </div>
</template>

<script>
import TieButton from '../tie-button/TieButton';

export default {
    name: 'PolicyCard',

    components: {
        TieButton
    },

    props: {
        policy: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.policy-card-wrapper {
    &:not(:last-of-type) {
        padding-bottom: 1.1rem;
    }
}

h2 {
    padding: 0 0 0 1rem;
    margin: 1rem 0 0.2rem;
    font-weight: 500;
    font-size: 1.17188rem;
    line-height: 1.4;

    &:first-child {
        margin-top: 0;
    }

    span {
        text-transform: lowercase;
        display: inline-block;

        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.card-section {
    background-color: #F8F8F8;
    border: 1px solid #DDDDDD;
    padding: 1rem 1rem;
    line-height: 1.5;
    border-radius: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.policy-information {
    font-weight: 500;

    span {
        font-weight: 400;
    }
}

a {
    line-height: inherit;
    color: #00ABC7;
    text-decoration: none;
    cursor: pointer;
}

a.button:focus,
a.button:hover {
    text-decoration: none;
    font-weight: 500;
}

.button:focus,
.button:hover {
    background-color: #0091A9;
    border-color: #0091A9;
    color: #FEFEFE;
}

.my-policies .policy-card {
    padding-left: 0.9rem;
}

@media (max-width: 1200px) {
    .policy-card {
        padding: 2rem 1.5rem;

        h4 {
            padding: 0 0 0 1rem;
            margin: 1rem 0 0.2rem;
            font-size: 1.1rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .button {
        font-size: 0.7rem;
        padding: 0.7rem;
    }

    .button.right {
        margin: 4px 0;
    }

    .card-section {
        padding: 0.4rem 0.6rem;
    }
}

.hide {
    display: none;
}
</style>