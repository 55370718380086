<template>
    <router-link
        class="back-button"
        :to="prevPage"
    >
        back
    </router-link>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'BackButton',

    computed: {
        ...mapGetters([
            'prevPage',
        ]),
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.back-button {
    display: none;
    position: absolute;
    right: 20px;
    //top: -10px;
    margin-top: 4px;
    z-index: 2;
    color: $primary-color;
    text-decoration: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.0' width='9' height='22' viewBox='0 0 140 140'><path fill='rgba(0,171,199,1)' d='M100.5,216.3c-3.4,0-6.9-1.4-9.3-4.3l-81-94c-4-4.6-4-11.4,0-16l81-94c4.4-5.1,12.1-5.7,17.3-1.3 c5.1,4.4,5.7,12.2,1.3,17.3l-74.1,86l74.1,86c4.4,5.1,3.8,12.9-1.3,17.3C106.2,215.3,103.3,216.3,100.5,216.3z'/></svg>");
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 14px;
    background-position: 0 -2px;
    font-size: 16px;
    font-weight: 400;
}
</style>