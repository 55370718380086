<template>
    <div
        class="sidebar"
        :class="{ 'open' : open }"
    >
        <transition name="slide">
            <div
                class="sidebar-panel"
            >
                <ul class="sidebar-panel-nav">
                    <li @click="closeMenu">
                        <router-link to="/dashboard/my-policies">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(0, 0, 32, 32))">
                            My Policies
                        </router-link>
                    </li>
                    <li @click="closeMenu">
                        <router-link to="/dashboard/customer-details">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(36, 0, 32, 32))">
                            My Profile
                        </router-link>
                    </li>
                    <li @click="closeMenu">
                        <router-link to="/dashboard/security-details">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(72, 0, 32, 32))">
                            Security Details
                        </router-link>
                    </li>
                    <li @click="closeMenu">
                        <router-link to="/dashboard/contact-us">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(108, 0, 32, 32))">
                            Contact Us
                        </router-link>
                    </li>
                    <li @click="closeMenu">
                        <router-link to="/dashboard/claim-centre">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(144, 0, 32, 32))">
                            Claim Centre
                        </router-link>
                    </li>
                    <li @click="closeMenu">
                        <router-link to="/logout">
                            <img src="@/assets/images/menu.svg#svgView(viewBox(180, 0, 32, 32))">
                            Log Out
                        </router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'HamburgerSidebar',
    computed: {
        open() {
            return this.$store.state.isSidebarOpen;
        }
    },
    methods: {
        closeMenu() {
            this.$store.commit('toggleSidebar');
        },
        logout() {
            this.$store.dispatch('logout');
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

@media screen and (min-width: 1199px) {
    .sidebar {
        display: flex;
    }
}

@media screen and (max-width: 1199px) {
    .sidebar {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    .sidebar-panel {
        position: absolute;
    }
}

.sidebar {
    &.open {
        display: flex;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s;
    }

    .sidebar-panel {
        overflow-y: auto;
        background-color: $primary-color;
        width: 100%;
        min-height: calc(100% - 91px);
        z-index: 999;
    }

    @media screen and (min-width: 720px) {
        .sidebar-panel {
            min-width: 400px;
        }
    }

    @media screen and (min-width: 327px) {
        .sidebar-panel {
            min-height: calc(100% - 108px);
        }
    }

    .router-link-active {
        background-color: $sidebar-hover-background-color;
    }
}

@media screen and (min-width: 1199px) {
    .logout-link {
        display: none;
    }
}

@media screen and (max-width: 1440px) {
    ul.sidebar-panel-nav > li > a {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5019607843);
    }
}

ul.sidebar-panel-nav {
    list-style-type: none;
    padding-left: 0;

    > li > a {
        color: #FFFFFF;
        text-decoration: none;
        display: block;
        padding: 2vw;
        font-weight: 500;

        img {
            height: 36px;
            width: 29px;
            vertical-align: middle;
            display: inline-block;
            margin: 0 20px 5px 0;
        }

        &:hover {
            background-color: $sidebar-hover-background-color;
        }
    }
}
</style>