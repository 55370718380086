<template>
    <select
        name="claim"
        class="select-claim false"
        :class="cssVariant"
        @change="$emit('update:modelValue', $event.target.value)"
    >
        <option value="" disabled :selected="modelValue === ''">
            <slot />
        </option>
        <option
            v-for="option in options"
            :key="option.id"
            :value="option.id"
        >
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: 'DropDown',

    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: String,
            required: false,
            default: '',
        },
        cssVariant: {
            type: String,
            required: false,
            default: '',
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            error: '',
        };
    },

};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

select {
    height: 3.29688rem;
    margin: 0 0 4px 0;
    padding: 0.9375rem 1.40625rem 0.9375rem 0.9375rem;
    appearance: none;
    border: 1px solid #F0F0F0;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    color: #0A0A0A;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%280, 0, 0%29"></polygon></svg>');
    background-origin: content-box;
    background-position: right -0.9375rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    width: 100%;
    border-bottom-color: #DDDDDD;
    background-color: #F8F8F8;
    font-size: 1em;
}

select:not([multiple]) {
    padding: 0.9375rem;
    line-height: 1;
}

@media (min-width: 1200px) {
    .content-block select {
        margin-bottom: 16px;
    }
}

option {
    background-color: #FFFFFF;
}

.error {
    color: #EC0044;
    background-color: #FFFFFF;
    border: 1px solid #EC0044;
    border-radius: 3px;
    font-size: 0.875em;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.select-error-field {
    border-color: #EC0044;
}
</style>