<template>
    <div class="alert" :class="`alert-${variant}`" role="alert">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TieAlert',

    props: {
        variant: {
            type: String,
            required: false,
            default: 'danger',
        }
    }
};
</script>

<style lang="scss" scoped>
    @import 'src/assets/styles/variables';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/mixins';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/alert';

    .alert {
        text-align: center;

        @media (min-width: $lg-screen) {
            padding-left: 70px;
            padding-right: 70px;
        }
    }

    .alert-danger {
        @include alert-variant($white, $danger, $danger);
    }

    .alert-success {
        @include alert-variant($white, $success, $success);
    }
</style>