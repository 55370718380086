<template>
    <label v-if="label.length > 0" :for="identifier">{{ label }}</label>
    <select
        v-if="options"
        :id="identifier"
        :value="modelValue"
        name="select-input"
        class="text-input"
        :class="{ error: error && error.length > 0 }"
        @input="$emit('update:modelValue', $event.target.value)"
    >
        <option
            v-if="placeholder"
            value=""
            disabled
            selected
        >
            {{ placeholder }}
        </option>
        <option v-for="option in sortedOptions" :key="option.key" :value="option.value">
            {{ option.key }}
        </option>
    </select>
    <tie-alert v-if="error.length > 0">
        {{ error }}
    </tie-alert>
</template>

<script>
import TieAlert from '../tie-alert/TieAlert';

export default {
    name: 'SelectInput',

    components: {
        TieAlert
    },

    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },

        identifier: {
            type: String,
            required: false,
            default: 'text-input',
        },

        placeholder: {
            type: String,
            required: false,
            default: '',
        },

        options: {
            type: Array,
            required: true,
        },

        'modelValue': {
            type: String,
            required: false,
            default: '',
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            error: '',
        };
    },

    computed: {
        sortedOptions() {
            return this.options.map(x => {
                return {
                    key: x.key ? x.key : x,
                    value: x.value ? x.value : x
                };
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
@import "src/assets/styles/forms";

label {
    font-weight: bold;
    margin: 14px 0 3px;
    position: relative;
}

</style>
